import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Card,
  Divider,
  InputBase,
  Modal,
  Paper,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ManagementContext } from "../../../contexts/managementContext";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import Select from "react-select";
import { selectDefaultStyles } from "../../selectStyles/stylev3";
import { RestaurantContext } from "../../../contexts/restaurantContext";
import restaurantService from "../../../services/restaurantService";
import { useToast } from "../../snackbar";

let discountArr = [];
for (let i = 0; i <= 99; i++) {
  discountArr.push({
    value: i,
    label: i + " %",
  });
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    padding: 20,
    border: "2px solid #000",
    boxShadow: 5,
    backgroundColor: "rgb(0 0 0 / 62%)",
    alignItems: "center",
    justifyContent: "center",
    overflow: "auto",
    maxHeight: "100vh",
  },
  paper: {
    width: "100%",
    borderRadius: 8,
  },
}));

const OfferPriceChangeModal = ({
  offerPriceChangeModal,
  setOfferPriceChangeModal,
  financeOfferRow,
  setFinanceOfferRow,
  restId,
  offerProductsObj,
}) => {
  const classes = useStyles();
  const { alert } = useToast();

  const {
    financeOfferFunc,
    financeOffer,
    getOfferDetail,
    offerDetail,
    setOfferDetail,
    financeOfferChangeFunc,
  } = useContext(ManagementContext);
  const { patchDiscountOffer, getTaskList } = useContext(RestaurantContext);
  const [financeOfferObj, setFinanceOfferObj] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [selectedDiscountRenew, setSelectedDiscountRenew] = useState(null);
  const [discountPermissions, setDiscountPermissions] = useState([]);

  const getDiscountAllowed = async () => {
    let offerId;

    if (offerProductsObj?.offer_id) {
      offerId = offerProductsObj?.offer_id;
    } else {
      offerId = financeOfferRow?.restofficial?.offers.find(
        (offer) => offer.active
      )?.id;
    }

    try {
      const allowedDiscounts = await restaurantService.get_discount_allowed(
        offerId
      );
      const permissions = allowedDiscounts.map((row) => {
        return {
          value: row,
          label: row + " %",
        };
      });
      setDiscountPermissions(permissions);
    } catch (err) {
      alert(err.response.data.message);
    }
  };

  useEffect(() => {
    if (financeOfferRow !== null) {
      getDiscountAllowed();

      financeOfferFunc({
        rest_id: financeOfferRow?.restofficial?.user_id,
      });
    }
  }, [financeOfferRow]);

  useEffect(() => {
    if (financeOffer !== null) {
      setFinanceOfferObj(financeOffer);
    }
  }, [financeOffer]);

  const hasRenewProduct = offerDetail?.detail?.filter((row) => row.renew);

  useEffect(() => {
    if (financeOfferObj) {
      setSelectedDiscount({
        value: 0,
        label: "0 %",
      });
      setSelectedDiscountRenew({
        value: 0,
        label: "0 %",
      });
      getOfferDetail(financeOfferObj.id, "", "");
    }
  }, [financeOfferObj]);

  useEffect(() => {
    if (offerDetail) {
      setSelectedDiscount({
        value: offerDetail?.new_discount,
        label: offerDetail?.new_discount + " %",
      });
      setSelectedDiscountRenew({
        value: offerDetail?.renew_discount,
        label: offerDetail?.renew_discount + " %",
      });
    }
  }, [offerDetail]);

  const totalPriceCalc = () => {
    let totalPrice = 0;
    offerDetail?.detail?.map((row) => {
      totalPrice = totalPrice + row?.last_price;
    });

    return totalPrice;
  };

  return (
    <>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={offerPriceChangeModal}
        className={classes.modal}
        onClose={() => {
          setOfferPriceChangeModal(false);
        }}
      >
        <Card
          className={classes.paper}
          style={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            maxHeight: 600,
          }}
        >
          {financeOfferObj !== null && (
            <div
              style={{
                width: "100%",
                overflow: "auto",
              }}
            >
              <Paper
                elevation={3}
                style={{
                  padding: 10,
                  backgroundColor: "#fff",
                  boxShadow: "#bebebebf 0px 0px 6px 2px",
                  color: "black",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    borderRadius: "3px",
                    alignItems: "center",
                    backgroundColor: "#f53b62",
                    marginBottom: 5,
                    padding: "5px 10px 5px 0px",
                    justifyContent: "space-between",
                  }}
                >
                  <div></div>
                  <div
                    style={{
                      fontSize: 16,
                      fontWeight: 600,
                      textTransform: "capitalize",
                      color: "#fff",
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {financeOffer?.restofficial_name}
                  </div>

                  <Tooltip title="Prim Tablosu">
                    <ReceiptLongIcon
                      style={{
                        cursor: "pointer",
                        fontSize: 30,
                        color: "#fff",
                      }}
                      onClick={() => {
                        window.open(`/operation/prim-table`, "_blank");
                      }}
                    />
                  </Tooltip>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      padding: 10,
                      background: "#f1f1f1",
                      borderRadius: 4,
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: 10,
                        fontSize: 15,
                        fontFamily: "segoeuiB",
                        color: "slategray",
                      }}
                    >
                      TOPLAM : ₺ {totalPriceCalc(offerDetail)}
                    </div>
                    <Divider
                      orientation="horizontal"
                      sx={{
                        backgroundColor: "#f4f4f4",
                        width: "100%",
                        height: "auto",
                        margin: "10px 0px",
                      }}
                    />
                    {offerDetail?.detail?.map((row, ind) => {
                      return (
                        <div key={ind}>
                          <div
                            style={{
                              fontSize: 14,
                              marginBottom: 3,
                              fontFamily: "segoeuiRg",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  width: 200,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    fontWeight: 600,
                                    marginLeft: 5,
                                  }}
                                >
                                  {row?.product_name}{" "}
                                </div>

                                {row.renew && (
                                  <div
                                    style={{
                                      color: "#3e66c3",
                                      marginLeft: 5,
                                      fontSize: 12,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    (Söz uzt.)
                                  </div>
                                )}
                              </div>

                              <div
                                style={{
                                  marginRight: 10,
                                  paddingRight: 10,
                                  textDecoration:
                                    offerDetail?.detail[ind]?.last_price !==
                                    offerDetail?.detail[ind]?.first_price
                                      ? "line-through"
                                      : "auto",
                                }}
                              >
                                ₺ {offerDetail?.detail[ind]?.first_price}{" "}
                              </div>
                              {offerDetail?.detail[ind]?.last_price !==
                                offerDetail?.detail[ind]?.first_price && (
                                <div>
                                  ₺ {offerDetail?.detail[ind]?.last_price}
                                </div>
                              )}

                              <InputBase
                                style={{
                                  width: 70,
                                  marginLeft: 10,
                                  padding: 5,
                                  background: "#fff",
                                  borderRadius: 5,
                                  textAlign: "center",
                                }}
                                value={row.agentDiscount}
                                onChange={(e) => {
                                  let newArr = [...offerDetail.detail];
                                  newArr[ind].agentDiscount = e.target.value;
                                  setOfferDetail({
                                    ...offerDetail,
                                    detail: newArr,
                                  });
                                }}
                              />
                            </div>

                            {offerDetail?.detail[ind]?.renew && (
                              <>
                                <div
                                  style={{
                                    background: "#3e66c3",
                                    color: "#fff",
                                    padding: "5px 10px",
                                    fontSize: 12,
                                    borderRadius: 10,
                                    marginLeft: 50,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Geçen seneki satış fiyatı <br />
                                  {
                                    offerDetail?.detail[ind]?.last_product_price
                                  }{" "}
                                  TL
                                </div>

                                <div
                                  style={{
                                    background: "#3e66c3",
                                    color: "#fff",
                                    padding: "5px 10px",
                                    fontSize: 11,
                                    borderRadius: 10,
                                    marginLeft: 5,
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Zam Oranı <br />
                                  {offerDetail?.detail[ind]?.raise_rate.toFixed(
                                    2
                                  )}{" "}
                                  %
                                </div>
                              </>
                            )}
                            <div
                              style={{
                                background: "#3e66c3",
                                color: "#fff",
                                padding: "5px 10px",
                                fontSize: 12,
                                borderRadius: 10,
                                marginLeft: 50,
                                textAlign: "center",
                                fontWeight: "bold",
                                whiteSpace: "nowrap",
                              }}
                            >
                              İndirim Oranı <br />%{" "}
                              {offerDetail?.detail[ind]?.discount}
                            </div>
                          </div>

                          <Divider
                            orientation="horizontal"
                            sx={{
                              backgroundColor: "#f4f4f4",
                              width: "100%",
                              height: "auto",
                              margin: "10px 0px",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div style={{ display: "flex" }}>
                  {hasRenewProduct?.length !==
                    financeOfferObj?.products?.length && (
                    <div style={{ marginRight: 20, width: 150 }}>
                      <span
                        style={{
                          color: "#293c51",
                          fontFamily: "segoeuiRg",
                          fontSize: 15,
                          fontWeight: 700,
                          marginLeft: 8,
                        }}
                      >
                        İndirim
                      </span>
                      <div
                        style={{
                          marginRight: 14,
                          marginTop: 10,
                          width: "150px",
                        }}
                      >
                        <Select
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                          options={discountPermissions}
                          styles={selectDefaultStyles}
                          isSearchable={true}
                          hoverColor={"black"}
                          menuColor={"#f4f4f4"}
                          color={"#000"}
                          placeholder={"Seçiniz"}
                          dynamiczIndex={3}
                          value={selectedDiscount}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            setSelectedDiscount(e);
                            if (hasRenewProduct?.length === 0) {
                              getOfferDetail(financeOfferObj.id, e.value, "");
                            } else {
                              getOfferDetail(
                                financeOfferObj.id,
                                e.value,
                                selectedDiscountRenew.value
                              );
                            }
                          }}
                          getOptionLabel={(e) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>{e.label}</span>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}

                  {hasRenewProduct?.length > 0 && (
                    <div>
                      <span
                        style={{
                          color: "#293c51",
                          fontFamily: "segoeuiRg",
                          fontSize: 15,
                          fontWeight: 700,
                          marginLeft: 8,
                        }}
                      >
                        Sözleşme Yenileme İndirim
                      </span>
                      <div style={{ marginRight: 14, marginTop: 10 }}>
                        <Select
                          menuPosition="fixed"
                          menuPortalTarget={document.body}
                          options={discountPermissions}
                          styles={selectDefaultStyles}
                          isSearchable={true}
                          hoverColor={"black"}
                          menuColor={"#f4f4f4"}
                          color={"#000"}
                          placeholder={"Seçiniz"}
                          dynamiczIndex={3}
                          value={selectedDiscountRenew}
                          getOptionValue={(option) => option.value}
                          onChange={(e) => {
                            setSelectedDiscountRenew(e);
                            if (hasRenewProduct?.length > 0) {
                              getOfferDetail(
                                financeOfferObj.id,
                                selectedDiscount.value,
                                e.value
                              );
                            } else {
                              getOfferDetail(financeOfferObj.id, 0, e.value);
                            }
                          }}
                          getOptionLabel={(e) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>{e.label}</span>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {offerDetail && (
                  <Paper
                    elevation={3}
                    style={{
                      padding: 10,
                      backgroundColor: "rgb(239,239,239)",
                      boxShadow: "#bebebebf 0px 0px 6px 2px",
                      color: "black",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <div
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        fontFamily: "segoeuiRg",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          background: "orange",
                          color: "#fff",
                          textAlign: "center",
                          padding: "5px 10px",
                          borderRadius: 10,
                          width: 150,
                        }}
                      >
                        İndirimsiz Kazancınız
                        <br /> {offerDetail?.first_prim?.toFixed(2)} ₺
                      </div>
                      <div
                        style={{
                          background: "red",
                          color: "#fff",
                          textAlign: "center",
                          padding: "5px 10px",
                          borderRadius: 10,
                          width: 150,
                        }}
                      >
                        Kaybınız
                        <br />
                        {(
                          offerDetail?.first_prim - offerDetail?.last_prim
                        ).toFixed(2)}{" "}
                        ₺
                      </div>
                      <div
                        style={{
                          background: "green",
                          color: "#fff",
                          textAlign: "center",
                          padding: "5px 10px",
                          borderRadius: 10,
                          width: 150,
                        }}
                      >
                        İndirimli Kazancınız
                        <br /> {offerDetail?.last_prim.toFixed(2)} ₺
                      </div>
                    </div>
                  </Paper>
                )}

                <div style={{ display: "flex", justifyContent: "end" }}>
                  <Button
                    style={{
                      backgroundColor: "#f53b62",
                      color: "#fff",
                      fontFamily: "segoeuiB",
                      width: 100,
                      display: "flex",
                      justifyContent: "center",
                      height: 40,
                      marginLeft: 10,
                    }}
                    size="small"
                    onClick={() => {
                      const agentDiscountArr = offerDetail.detail.filter(
                        (row) =>
                          row.agentDiscount && Number(row.agentDiscount) !== 0
                      );

                      const discountApproveArr = financeOfferObj.products.map(
                        (row, i) => {
                          return {
                            amount: agentDiscountArr[i]?.agentDiscount
                              ? Number(agentDiscountArr[i]?.agentDiscount)
                              : row.amount,
                            id: row.id,
                            name: row.name,
                            renew: row.renew,
                          };
                        }
                      );

                      if (agentDiscountArr.length > 0) {
                        const data = {
                          rest_id: financeOfferObj?.id,
                          sendObj: {
                            id: financeOfferObj?.id,
                            products: discountApproveArr,
                          },
                        };
                        financeOfferChangeFunc(data, "agent");
                      } else {
                        patchDiscountOffer(
                          financeOfferObj.id,
                          {
                            discount_new: selectedDiscount.value,
                            discount_renew: selectedDiscountRenew.value,
                          },
                          restId
                        );
                      }

                      setOfferPriceChangeModal(false);
                      setSelectedDiscount({
                        value: 0,
                        label: "0 %",
                      });
                      setSelectedDiscountRenew({
                        value: 0,
                        label: "0 %",
                      });
                      setOfferDetail(null);
                      setFinanceOfferRow(null);
                      getTaskList();
                    }}
                  >
                    Onayla
                  </Button>
                </div>

                {/* <div
                  style={{
                    fontSize: 14,
                    marginBottom: 3,
                    fontFamily: "segoeuiRg",
                    display: "flex",
                  }}
                >
                  <div style={{ width: 80 }}>Toplam Fiyat</div> : ₺{" "}
                  {financeOffer?.total_amount}
                </div>
                <div
                  style={{
                    fontSize: 14,
                    marginBottom: 3,
                    display: "flex",
                    fontFamily: "segoeuiRg",
                  }}
                >
                  <div style={{ width: 80, textAlign: "start" }}>
                    Toplam Eft
                  </div>{" "}
                  : ₺ {financeOffer?.eft_amount}
                </div> */}
              </Paper>
            </div>
          )}
        </Card>
      </Modal>
    </>
  );
};

export default OfferPriceChangeModal;
