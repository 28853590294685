import React, { createContext, useState } from "react";
import managementService from "../services/managementService";
import { useToast } from "../components/snackbar";
import { useHistory } from "react-router-dom";
import callService from "../services/callService";
import moment from "moment";

export const ManagementContext = createContext();

const ManagementProvider = (props) => {
  let history = useHistory();
  const { success, alert } = useToast();
  const [officeUserCosts, setOfficeUserCosts] = useState(null);
  const [primResult, setPrimResult] = useState([]);
  const [filteredReports, setFilteredReports] = useState({});
  const [managerWorksList, setManagerWorksList] = useState(null);
  const [paymentTracker, setPaymentTracker] = useState(null);
  const [graphs, setGraphs] = useState([]);
  const [applicationTypes, setApplicationTypes] = useState([]);
  const [ratingsInfo, setRatingsInfo] = useState({});
  const [restaurantServicesInfo, setRestaurantServicesInfo] = useState(null);
  const [restaurantServicesGrahps, setRestaurantServicesGrahps] =
    useState(null);
  const [optionalProducts, setOptionalProducts] = useState([]);
  const [dataResult, setDataResult] = useState(null);
  const [sellerUserList, setSellerUserList] = useState(null);
  const [kpiOutbound, setKpiOutbound] = useState(null);
  const [kpiSetup, setKpiSetup] = useState(null);
  const [kpiCollector, setKpiCollector] = useState(null);
  const [kpiTechSupports, setKpiTechSupports] = useState(null);
  const [setupCount, setSetupCount] = useState(null);
  const [smsSettingsList, setSmsSettingsList] = useState(null);
  const [collectorData, setCollectorData] = useState(null);
  const [collectorDataRenew, setCollectorDataRenew] = useState(null);
  const [kpiTickets, setKpiTickets] = useState(null);
  const [kpiStateHistory, setKpiStateHistory] = useState(null);
  const [currentList, setCurrentList] = useState(null);
  const [primSettings, setPrimSettings] = useState(null);
  const [dashboardOfficeuserList, setDashboardOfficeuserList] = useState(null);
  const [dashboardOfficeuserDetail, setDashboardOfficeuserDetail] =
    useState(null);
  const [blockedReasons, setBlockedReasons] = useState(null);
  const [deletedReasons, setDeletedReasons] = useState(null);
  const [officeUserApplicationTypes, setOfficeUserApplicationTypes] =
    useState(null);
  const [payedOffers, setPayedOffers] = useState(null);
  const [officeUserDetailCalendar, setOfficeUserDetailCalendar] =
    useState(null);
  const [officeUserDetailTaskRepeats, setOfficeUserDetailTaskRepeats] =
    useState(null);
  const [officeUserDetailCompletedTasks, setOfficeUserDetailCompletedTasks] =
    useState(null);
  const [taskRepeatDates, setTaskRepeatDates] = useState(null);
  const [dashboardUserRestofficials, setDashboardUserRestofficials] =
    useState(null);
  const [dashboardProductRenewals, setDashboardProductRenewals] = useState([]);
  const [dashboardProductRenewalsDetail, setDashboardProductRenewalsDetail] =
    useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [dictionaryHeaders, setDictionaryHeaders] = useState([]);
  const [dictionaryFeatures, setDictionaryFeatures] = useState([]);
  const [dashboardLoader, setDashboardLoader] = useState(false);
  const [officeUserDetailActiveTasks, setOfficeUserDetailActiveTasks] =
    useState(null);
  const [collectorTransactionData, setCollectorTransactionData] =
    useState(null);
  const [financeOffer, setFinanceOffer] = useState(null);
  const [smsTemplateList, setSmsTemplateList] = useState(null);
  const [platformAndProducts, setPlatformAndProdcucts] = useState(null);
  const [popupTemplateList, setPopupTemplateList] = useState(null);
  const [buttonActionsList, setButtonActionsList] = useState(null);
  const [bannerTemplateList, setBannerTemplateList] = useState(null);
  const [bannerList, setBannerList] = useState(null);
  const [printingPressList, setPrintingPressList] = useState([]);
  const [openPrintUserModal, setOpenPrintUserModal] = useState(false);
  const [baskiTypes, setBaskiTypes] = useState([]);
  const [printingRequestList, setPrintingRequestList] = useState({});
  const [printingRequestDetail, setPrintingRequestDetail] = useState(null);
  const [dailyPaymentList, setDailyPaymentList] = useState(null);
  const [brochurePicture, setBrochurePicture] = useState(null);
  const [brochureLoading, setBrochureLoading] = useState(null);
  const [brochureDownloadLoading, setBrochureDownloadLoading] = useState(null);
  const [stateHistoryPastList, setStateHistoryPastList] = useState(null);
  const [selectedProductPrimSettings, setSelectedProductPrimSettings] =
    useState(null);
  const [offerDetail, setOfferDetail] = useState(null);
  const [extraPrimSettings, setExtraPrimSettings] = useState(null);
  const [brochureResResult, setBrochureResResult] = useState(null);
  const [userRoles, setUserRoles] = useState(null);
  const [userDepartments, setUserDepartments] = useState(null);
  const [userSubdepartments, setUserSubdepartments] = useState(null);
  const [officeUserDetail, setOfficeUserDetail] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [dashboardCdrList, setDashboardCdrList] = useState(null);
  const [dashboardCdrFilterList, setDashboardCdrFilterList] = useState(null);
  const [dashboardCdrStatsList, setDashboardCdrStatsList] = useState(null);
  const [ratingSettingsList, setRatingSettingsList] = useState(null);
  const [ratingListNew, setRatingListNew] = useState(null);
  const [ratingListEducation, setRatingListEducation] = useState(null);
  const [ratingListCompleted, setRatingListCompleted] = useState(null);
  const [cdrRepeatsList, setCdrRepeatsList] = useState(null);
  const [cdrOutboundDurationsList, setCdrOutboundDurationsList] =
    useState(null);
  const [cdrInboundDurationsList, setCdrInboundDurationsList] = useState(null);
  const [tahsilatRatingList, setTahsilatRatingList] = useState(null);
  const [referencePayments, setReferencePayments] = useState(null);
  const [multiProductsDiscounts, setMultiProductsDiscounts] = useState(null);

  const officeUserCostsFunc = () => {
    managementService
      .officeuser_costs()
      .then((response) => {
        setOfficeUserCosts(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const officeUserCostsChangeFunc = (data) => {
    managementService
      .officeuser_costs_change(data)
      .then((response) => {
        success("Maliyet Ayarları Güncellendi.");
        officeUserCostsFunc();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getPaidableUsers = async (control) => {
    try {
      const result = await managementService.paidable_prim(control);
      setPrimResult(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const fetchFilteredReports = async (
    selectedStartDate,
    selectedEndDate,
    workerSelect
  ) => {
    try {
      const result = await managementService.manager_selected_reports(
        selectedStartDate,
        selectedEndDate,
        workerSelect?.user_id
      );

      setFilteredReports(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getManagerWorks = async (data) => {
    try {
      const result = await managementService.get_manager_works(data);

      setManagerWorksList(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getPaymentTracker = async (data) => {
    try {
      const result = await managementService.get_payment_tracker(data);

      setPaymentTracker(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getGraphs = async (year, userId) => {
    try {
      const result = await managementService.get_graphs(year, userId);

      setGraphs(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getApplicationTypes = async () => {
    try {
      const result = await managementService.get_application_types();

      setApplicationTypes(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getServicesReportsRating = async (userId, page) => {
    try {
      const result = await managementService.get_services_reports_rating(
        userId,
        page
      );

      setRatingsInfo(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getRestaurantServices = async (data) => {
    try {
      const result = await managementService.get_restaurant_services(data);

      setRestaurantServicesInfo(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getRestaurantServicesGraphs = async (data) => {
    try {
      const result = await managementService.get_restaurant_services_graphs();

      setRestaurantServicesGrahps(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getOptionalProducts = async (data) => {
    try {
      const result = await managementService.get_optional_products();

      setOptionalProducts(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const getDataResult = async (searchValue) => {
    try {
      const result = await managementService.data_search_result(searchValue);
      setDataResult(result);
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const patchDataResult = async (data, searchValue) => {
    try {
      const result = await managementService
        .patch_data_result(data)
        .then(() => {
          getDataResult(searchValue);
          success("İşlem Başarılı");
        });
      return result;
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  };

  const sellerUserListFunc = async () => {
    managementService
      .seller_user_list()
      .then((response) => {
        setSellerUserList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getKpiOutbound = (data) => {
    managementService
      .get_kpi_outbound(data)
      .then((response) => {
        setKpiOutbound(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getKpiSetup = (data) => {
    managementService
      .get_kpi_setup(data)
      .then((response) => {
        setKpiSetup(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getKpiCollector = (data) => {
    managementService
      .get_kpi_collector(data)
      .then((response) => {
        setKpiCollector(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getSetupCount = (data) => {
    managementService
      .get_setup_count(data)
      .then((response) => {
        setSetupCount(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getSmsSettings = () => {
    managementService
      .get_sms_settings()
      .then((response) => {
        setSmsSettingsList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sendSmsSetting = (data) => {
    managementService
      .send_sms_setting(data)
      .then((response) => {
        getSmsSettings();
        success("İşlem Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const editSmsSetting = (data) => {
    managementService
      .edit_sms_setting(data)
      .then((response) => {
        success("İşlem Başarılı");
        getSmsSettings();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteSmsSetting = (id) => {
    managementService
      .delete_sms_setting(id)
      .then((response) => {
        success("İşlem Başarılı");
        getSmsSettings();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getCollectorData = (data) => {
    managementService
      .get_collector_data(data)
      .then((response) => {
        setCollectorData(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getCollectorDataRenew = (data) => {
    managementService
      .get_collector_data_renew(data)
      .then((response) => {
        setCollectorDataRenew(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getCollectorTreansactionData = (data) => {
    managementService
      .get_collector_transaction_data(data)
      .then((response) => {
        setCollectorTransactionData(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getKpiTickets = (data) => {
    managementService
      .get_kpi_tickets(data)
      .then((response) => {
        setKpiTickets(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getKpiStateHistory = (data) => {
    managementService
      .get_kpi_state_history(data)
      .then((response) => {
        setKpiStateHistory(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getCurrentList = (page, rowsPerPage) => {
    managementService
      .get_current_list(page, rowsPerPage)
      .then((response) => {
        setCurrentList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const managePrimSettings = () => {
    managementService
      .manage_prim_settings()
      .then((response) => {
        setPrimSettings(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const changePrimSettings = (data) => {
    managementService
      .change_prim_settings(data)
      .then((response) => {
        managePrimSettings();
        success("Prim ayarları güncellendi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuser = () => {
    setDashboardLoader(true);
    managementService
      .dashboard_officeuser_v2()
      .then((response) => {
        setDashboardOfficeuserList(response);
        setDashboardLoader(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailFunc = (data) => {
    managementService
      .dashboard_officeuser_detail(data)
      .then((response) => {
        setDashboardOfficeuserDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailDeleteReasons = (data) => {
    managementService
      .dashboard_officeuser_delete_reasons(data)
      .then((response) => {
        setDeletedReasons(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailBlockedReasons = (data) => {
    managementService
      .dashboard_officeuser_blocked_reasons(data)
      .then((response) => {
        setBlockedReasons(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailApplicationTypes = (data) => {
    managementService
      .dashboard_officeuser_application_types(data)
      .then((response) => {
        setOfficeUserApplicationTypes(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailPayedOffers = (data) => {
    managementService
      .dashboard_officeuser_payed_offers(data)
      .then((response) => {
        setPayedOffers(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailCompletedTasks = (data) => {
    managementService
      .dashboard_officeuser_completed_tasks(data)
      .then((response) => {
        setOfficeUserDetailCompletedTasks(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailCalendar = (data) => {
    managementService
      .dashboard_officeuser_calendar(data)
      .then((response) => {
        setOfficeUserDetailCalendar(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailCalendarTech = (data) => {
    managementService
      .dashboard_officeuser_calendar_tech(data)
      .then((response) => {
        setOfficeUserDetailCalendar(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailTaskRepeats = (data) => {
    managementService
      .dashboard_officeuser_task_repeats(data)
      .then((response) => {
        setOfficeUserDetailTaskRepeats(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailTaskRepeatsDates = (data) => {
    managementService
      .dashboard_officeuser_task_repeats_dates(data)
      .then((response) => {
        setTaskRepeatDates(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailActiveStatus = (data, data2) => {
    managementService
      .dashboard_officeuser_active_status(data)
      .then((response) => {
        dashboardOfficeuserDetailFunc(data2);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailTaskLock = (data, data2) => {
    managementService
      .dashboard_officeuser_task_lock(data)
      .then((response) => {
        dashboardOfficeuserDetailFunc(data2);
        success("İşlem Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserDetailUserDelete = (user_id) => {
    managementService
      .dashboard_officeuser_delete(user_id)
      .then((response) => {
        success("İşlem Başarılı");
        dashboardOfficeuser();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserTransfer = (data) => {
    managementService
      .dashboard_officeuser_transfer(data)
      .then((response) => {
        success("Transfer başarılı");
        dashboardOfficeuserRestofficials(data.from_officeuser);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserTransferRestofficials = (data) => {
    managementService
      .dashboard_officeuser_transfer_restofficials(data)
      .then((response) => {
        success("Transfer başarılı");
        dashboardOfficeuserRestofficials(data.from_officeuser);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserRestofficials = (user_id) => {
    managementService
      .dashboard_officeuser_restofficials(user_id)
      .then((response) => {
        setDashboardUserRestofficials(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserProductRenewals = (data) => {
    managementService
      .dashboard_officeuser_product_renewals(data)
      .then((response) => {
        setDashboardProductRenewals(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserProductRenewalsRestaurants = (data) => {
    managementService
      .dashboard_officeuser_product_renewals_restaurants(data)
      .then((response) => {
        setDashboardProductRenewalsDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserInfo = (data) => {
    managementService
      .dashboard_officeuser_info(data)
      .then((response) => {
        setUserInfo(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getDictionaryHeaders = (searchValue) => {
    managementService
      .get_dictionary_headers(searchValue)
      .then((response) => {
        setDictionaryHeaders(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const addDictionaryHeaders = (data) => {
    managementService
      .add_dictionary_header(data)
      .then((response) => {
        getDictionaryHeaders("");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const editDictionaryHeaders = (data) => {
    managementService
      .edit_dictionary_header(data)
      .then((response) => {
        getDictionaryHeaders("");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteDictionaryHeaders = (id) => {
    managementService
      .delete_dictionary_header(id)
      .then((response) => {
        getDictionaryHeaders("");
        success("İşlem Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const addDictionaryFeature = (data, headerId) => {
    managementService
      .add_dictionary_feature(data, headerId)
      .then((response) => {
        getDictionaryFeatures(headerId);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getDictionaryFeatures = (headerId) => {
    managementService
      .get_dictionary_features(headerId)
      .then((response) => {
        setDictionaryFeatures(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteDictionaryFeature = (headerId, featureId) => {
    managementService
      .delete_dictionary_feature(headerId, featureId)
      .then((response) => {
        getDictionaryFeatures(headerId);
        success("İşlem Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const editDictionaryFeature = (headerId, featureId, data) => {
    managementService
      .edit_dictionary_feature(headerId, featureId, data)
      .then((response) => {
        getDictionaryFeatures(headerId);
        success("İşlem Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserTech = () => {
    setDashboardLoader(true);
    managementService
      .dashboard_officeuser_tech()
      .then((response) => {
        setDashboardOfficeuserList(response);
        success("Teknik personel bilgileri getirildi.");
        setDashboardLoader(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserTechDetailFunc = (data) => {
    managementService
      .dashboard_officeuser_tech_detail(data)
      .then((response) => {
        setDashboardOfficeuserDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserTechActiveTasks = (data) => {
    managementService
      .dashboard_officeuser_tech_active_tasks(data)
      .then((response) => {
        setOfficeUserDetailActiveTasks(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardOfficeuserTechTicketBounces = (data) => {
    managementService
      .dashboard_officeuser_tech_ticket_bounces(data)
      .then((response) => {
        setUserInfo(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const financeOfferFunc = (data) => {
    managementService
      .finance_offer(data)
      .then((response) => {
        setFinanceOffer(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const financeOfferChangeFunc = (data, type = null) => {
    managementService
      .finance_offer_change(data)
      .then((response) => {
        success("Fiyat Onaylandı.");
        if (type !== "agent") {
          financeOfferFunc({ rest_id: data?.rest_id });
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const accountingCurrentToKunyeFunc = (data) => {
    managementService
      .accountingCurrentToKunye(data)
      .then((response) => {
        history.push(`/restaurants/${response.user_id}`);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const smsTemplateListFunc = () => {
    managementService
      .sms_template_list()
      .then((response) => {
        setSmsTemplateList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createSmsTemplateFunc = (data) => {
    managementService
      .create_sms_template(data)
      .then((response) => {
        smsTemplateListFunc();
        data.setSmsHeader("");
        data.setSmsContent("");
        data.setSmsType(null);
        data.setSmsLink("");
        data.setSelectedAppProduct(null);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createSmsTemplateTestFunc = (data) => {
    managementService
      .create_sms_template_test(data)
      .then((response) => {
        smsTemplateListFunc();
        data.setSmsHeader("");
        data.setSmsContent("");
        data.setSmsType(null);
        data.setSmsLink("");
        data.setSmsTest(false);
        data.setTestPhone("90");
        data.setSelectedAppProduct(null);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const platformAndProductsFunc = () => {
    managementService
      .platform_and_products()
      .then((response) => {
        setPlatformAndProdcucts(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const publishSmsFunc = (data) => {
    managementService
      .publish_sms(data)
      .then((response) => {})
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const popupTemplateListFunc = () => {
    managementService
      .popup_template_list()
      .then((response) => {
        setPopupTemplateList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createPopupTemplateFunc = (data) => {
    managementService
      .create_popup_template(data)
      .then((response) => {
        popupTemplateListFunc();
        data.setPopupHeader("");
        data.setPopupContent("");
        data.setPopupType(null);
        data.setSelectedAppProduct(null);
        data.setSelectedButtonActions(null);
        data.setSelectedForwardPage(null);
        data.setImage1(null);
        data.setSendingBannerImage(null);
        data.setImage2(null);
        data.setSendingBannerImage2(null);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const buttonActionsListFunc = () => {
    managementService
      .button_actions()
      .then((response) => {
        setButtonActionsList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const publishPopupFunc = (data) => {
    managementService
      .publish_popup(data)
      .then((response) => {
        data.setSendSettingsModal(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const bannerTemplateListFunc = () => {
    managementService
      .banner_template_list()
      .then((response) => {
        setBannerTemplateList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createBannerTemplateFunc = (data) => {
    managementService
      .create_banner_template(data)
      .then((response) => {
        bannerTemplateListFunc();
        data.setBannerHeader("");
        data.setBannerContent("");
        data.setBannerType(null);
        data.setSelectedAppProduct(null);
        data.setSelectedButtonActions(null);
        data.setSelectedForwardPage(null);
        data.setImage1(null);
        data.setImage2(null);
        data.setSendingBannerImage(null);
        data.setEditorState(null);
        data.setBlackListOnClick(false);
        data.setSelectedPriority(null);
        data.setSelectedShowTime(null);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const publishBannerFunc = (data) => {
    managementService
      .publish_banner(data)
      .then((response) => {
        data.setSendSettingsModal(false);
        bannerListFunc({ page: 1 });
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const deleteBannerTemplateFunc = (data) => {
    managementService
      .delete_banner_template(data)
      .then((response) => {
        bannerTemplateListFunc();
        success("Template Silindi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const bannerTemplateChangeFunc = (data) => {
    managementService
      .banner_template_change(data)
      .then((response) => {
        bannerTemplateListFunc();
        success("Banner Template Güncellendi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const bannerListFunc = (data) => {
    managementService
      .banner_list(data)
      .then((response) => {
        setBannerList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const bannerChangeFunc = (data) => {
    managementService
      .banner_change(data)
      .then((response) => {
        bannerListFunc({ page: data.page });
        success("Banner durumu değiştirildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getPrintingPressList = () => {
    managementService
      .get_printing_press_list()
      .then((response) => {
        setPrintingPressList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createPrintingPressUser = (data) => {
    managementService
      .create_printing_press_user(data)
      .then((response) => {
        getPrintingPressList();
        setOpenPrintUserModal(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const editPrintingPressUser = (data) => {
    managementService
      .edit_printing_press_user(data)
      .then((response) => {
        getPrintingPressList();
        setOpenPrintUserModal(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getBaskiTypes = () => {
    managementService
      .get_baski_types()
      .then((response) => {
        setBaskiTypes(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createNewBaski = (data) => {
    managementService
      .create_new_baski(data)
      .then((response) => {
        getBaskiTypes();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const editBaskiDetail = (data) => {
    managementService
      .edit_baski_detail(data)
      .then((response) => {
        getBaskiTypes();
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getPrintingRequestList = (page, restId) => {
    managementService
      .get_printing_request_list(page, restId)
      .then((response) => {
        setPrintingRequestList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getBrochurePicture = (id) => {
    setBrochureLoading(true);
    managementService
      .get_brochure_picture(id)
      .then((response) => {
        setBrochurePicture(response);
        setBrochureLoading(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const brochurePictureDownload = (data, type) => {
    setBrochureDownloadLoading(true);
    managementService
      .brochure_pic_download(data)
      .then((response) => {
        if (data.type === "pdf") {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `brochure_${data.id}.pdf`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else if (data.type === "png") {
          const base64Image =
            "data:image/jpg;base64," + response?.brochure_base64;
          const link = document.createElement("a");
          link.href = base64Image;
          link.download = `brochure_${data.id}.png`;
          link.click();
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response], { type: "image/jpeg" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `brochure_${data.id}.jpg`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
        setBrochureDownloadLoading(false);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getPrintingRequestDetail = (id) => {
    managementService
      .get_printing_request_detail(id)
      .then((response) => {
        setPrintingRequestDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const editPrintingRequestDetail = (id, data, page, restId) => {
    managementService
      .edit_printing_request_detail(id, data)
      .then((response) => {
        getPrintingRequestList(page, restId);
        success("İşlem Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getBaskiRequestsFinance = (page) => {
    managementService
      .get_baski_requests_finance(page)
      .then((response) => {
        setPrintingRequestList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dailyPaymentListFunc = () => {
    managementService
      .daily_payment()
      .then((response) => {
        setDailyPaymentList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dailyPaymentPatchFunc = (data) => {
    managementService
      .daily_payment_patch(data)
      .then((response) => {
        success("Ödeme durumu değiştirildi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dailyPaymentDeleteFunc = (data) => {
    managementService
      .daily_payment_delete(data)
      .then((response) => {
        success("Ödeme durumu silindi.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dailyPaymentExelFunc = () => {
    managementService
      .daily_payment_exel()
      .then((response) => {
        dailyPaymentListFunc();
        success(response?.message);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const actionPrintingRequestFinance = (data, id, page) => {
    managementService
      .action_printing_request_finance(data, id)
      .then((response) => {
        getBaskiRequestsFinance(page);
        success("İşlem Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const stateHistoryPastListFunc = (data) => {
    managementService
      .state_history_past_list(data)
      .then((response) => {
        const dashboardOfficeuserListObj = Object.assign(
          [],
          JSON.parse(JSON.stringify({ ...dashboardOfficeuserList }))
        );
        let index = dashboardOfficeuserListObj.findIndex(
          (item) => item.user_id === data.sendingObj?.officeuser
        );
        if (index !== -1) {
          dashboardOfficeuserListObj[index].state_info = response;
          setDashboardOfficeuserList(dashboardOfficeuserListObj);
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createPrintingRequest = (data, page) => {
    managementService
      .create_printing_request(data)
      .then((response) => {
        success("Baskı İsteği Oluşturuldu");
        getPrintingRequestList(page + 1, data.restofficial_id);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getProductPrimSettings = (productId) => {
    managementService
      .get_product_prim_settings(productId)
      .then((response) => {
        setSelectedProductPrimSettings(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const sendPrimSettings = (data) => {
    managementService
      .send_prim_settings(data)
      .then((response) => {
        getProductPrimSettings(data.product);
        success("İşlem Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getOfferDetail = (offerId, discount, discountRenew) => {
    managementService
      .get_offer_detail(offerId, discount, discountRenew)
      .then((response) => {
        setOfferDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const brochureSendRestaurant = (id) => {
    managementService
      .brochure_send_restaurant(id)
      .then((response) => {})
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getExtraPrimSettings = () => {
    managementService
      .get_extra_prim_settings()
      .then((response) => {
        setExtraPrimSettings(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const addExtraPrimSettings = (data) => {
    managementService
      .add_extra_prim_settings(data)
      .then((response) => {
        getExtraPrimSettings();
        success("İşlem Başarılı");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getOfficeUser = (userId) => {
    managementService
      .get_office_user(userId)
      .then((response) => {
        setOfficeUserDetail(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createOfficeUser = (data) => {
    managementService
      .create_office_user(data)
      .then((response) => {
        dashboardOfficeuser();
        success("İşlem Başarılı");
        setShowAddUserModal(false);
      })
      .catch((error) => {
        setShowAddUserModal(true);
        alert(error?.response?.data?.message);
      });
  };

  const editOfficeUser = (data, userId, dashboardData) => {
    managementService
      .edit_office_user(data, userId)
      .then((response) => {
        dashboardOfficeuserDetailFunc(dashboardData);
        dashboardOfficeuser();
        success("İşlem Başarılı");
        setShowAddUserModal(false);
      })
      .catch((error) => {
        setShowAddUserModal(true);
        alert(error?.response?.data?.message);
      });
  };

  const getUserRoles = () => {
    managementService
      .get_user_roles()
      .then((response) => {
        setUserRoles(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getUserSubdepartments = () => {
    managementService
      .get_user_subdepartments()
      .then((response) => {
        setUserSubdepartments(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const getUserDepartments = () => {
    managementService
      .get_user_departments()
      .then((response) => {
        setUserDepartments(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardCdrListFunc = (data) => {
    managementService
      .daskboard_cdr_list(data)
      .then((response) => {
        setDashboardCdrList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardCdrFiltersFunc = () => {
    managementService
      .daskboard_cdr_filters()
      .then((response) => {
        setDashboardCdrFilterList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const dashboardCdrStatsFunc = (data) => {
    managementService
      .daskboard_cdr_stats(data)
      .then((response) => {
        setDashboardCdrStatsList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ratingSettingsListFunc = () => {
    managementService
      .rating_settings_list()
      .then((response) => {
        setRatingSettingsList(response);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const createRatingSettingsFunc = (data) => {
    managementService
      .create_rating_settings(data)
      .then((response) => {
        success("Puantaj ayarı oluşturuldu", 4500);
        ratingSettingsListFunc();
        data.setCreateNewRatingSetting(null);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ratingListFunc = (data) => {
    managementService
      .rating_list(data)
      .then((response) => {
        if (data.sendObj.state === "new") {
          setRatingListNew(response);
        } else if (data.sendObj.state === "education") {
          setRatingListEducation(response);
        } else {
          setRatingListCompleted(response);
        }
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ratingRateFunc = (data) => {
    managementService
      .rating_rate(data)
      .then(() => {
        success("Puantaj yapıldı.");
        let dataNew = {
          sendObj: {
            start_date: moment(data.startDate).format("YYYY-MM-DD"),
            end_date: moment(data.endDate).format("YYYY-MM-DD"),
            state: "new",
            officeuser: "",
            task_type: "",
          },
        };
        let dataEdu = {
          sendObj: {
            start_date: moment(data.startDate).format("YYYY-MM-DD"),
            end_date: moment(data.endDate).format("YYYY-MM-DD"),
            state: "education",
            officeuser: "",
            task_type: "",
          },
        };
        let dataComp = {
          sendObj: {
            start_date: moment(data.startDate).format("YYYY-MM-DD"),
            end_date: moment(data.endDate).format("YYYY-MM-DD"),
            state: "completed",
            officeuser: "",
            task_type: "",
          },
        };
        ratingListFunc(dataNew);
        ratingListFunc(dataEdu);
        ratingListFunc(dataComp);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const ratingRateTahsilatFunc = (data) => {
    managementService
      .rating_rate(data)
      .then(() => {
        tahsilatRatingFunc({ rating_id: data.rating_id });
        success("Puantaj yapıldı.");
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const puantajRecordFunc = (data) => {
    callService
      .cdr_recording(data)
      .then((response) => {
        if (data.list === "new") {
          const ratingListNewObj = Object.assign(
            [],
            JSON.parse(JSON.stringify({ ...ratingListNew }))
          );
          let voiceIndex = ratingListNewObj.findIndex(
            (item) => item.cdr?.id === data.record_id
          );
          if (voiceIndex !== -1) {
            ratingListNewObj[voiceIndex].cdr.voice = response?.recording_url;
            setRatingListNew(ratingListNewObj);
          }
        } else if (data.list === "education") {
          const ratingListEducationObj = Object.assign(
            [],
            JSON.parse(JSON.stringify({ ...ratingListEducation }))
          );
          let voiceIndex = ratingListEducationObj.findIndex(
            (item) => item.cdr?.id === data.record_id
          );
          if (voiceIndex !== -1) {
            ratingListEducationObj[voiceIndex].cdr.voice =
              response?.recording_url;
            setRatingListEducation(ratingListEducationObj);
          }
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const ratingEducationNoteFunc = (data) => {
    managementService
      .rating_education_note(data)
      .then(() => {
        success("Puantaj yapıldı.");
        let dataNew = {
          sendObj: {
            start_date: moment(data.startDate).format("YYYY-MM-DD"),
            end_date: moment(data.endDate).format("YYYY-MM-DD"),
            state: "new",
            officeuser: "",
            task_type: "",
          },
        };
        let dataEdu = {
          sendObj: {
            start_date: moment(data.startDate).format("YYYY-MM-DD"),
            end_date: moment(data.endDate).format("YYYY-MM-DD"),
            state: "education",
            officeuser: "",
            task_type: "",
          },
        };
        let dataComp = {
          sendObj: {
            start_date: moment(data.startDate).format("YYYY-MM-DD"),
            end_date: moment(data.endDate).format("YYYY-MM-DD"),
            state: "completed",
            officeuser: "",
            task_type: "",
          },
        };
        ratingListFunc(dataNew);
        ratingListFunc(dataEdu);
        ratingListFunc(dataComp);
      })
      .catch((error) => {
        alert(error?.response?.data?.message);
      });
  };

  const santralRecordFunc = (data) => {
    callService
      .cdr_recording(data)
      .then((response) => {
        const dashboardCdrListObj = Object.assign(
          {},
          JSON.parse(JSON.stringify({ ...dashboardCdrList }))
        );
        let voiceIndex = dashboardCdrListObj.results.findIndex(
          (item) => item.id === data.record_id
        );
        if (voiceIndex !== -1) {
          dashboardCdrListObj.results[voiceIndex].voice =
            response?.recording_url;
          setDashboardCdrList(dashboardCdrListObj);
        }
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const ratingSettingAddFunc = (data) => {
    managementService
      .rating_setting_add(data)
      .then((response) => {
        success("Puantaj güncellendi", 4500);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const ratingSettingDeleteFunc = (data) => {
    managementService
      .rating_setting_delete(data)
      .then((response) => {
        success("Puantaj güncellendi", 4500);
        ratingSettingsListFunc();
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrRepeatsListFunc = (data) => {
    managementService
      .cdr_repeats_list(data)
      .then((response) => {
        setCdrRepeatsList(response);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrOutboundDurationListFunc = (data) => {
    managementService
      .cdr_outbound_duration_list(data)
      .then((response) => {
        setCdrOutboundDurationsList(response);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const cdrInboundDurationListFunc = (data) => {
    managementService
      .cdr_inbound_duration_list(data)
      .then((response) => {
        setCdrInboundDurationsList(response);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const tahsilatRatingFunc = (data) => {
    managementService
      .tahsilat_rating(data)
      .then((response) => {
        setTahsilatRatingList(response);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const tahsilatRatingSkipFunc = (data) => {
    managementService
      .tahsilat_rating_skip(data)
      .then((response) => {
        success("Puanlama yapılamadı olarak güncellendi");
        tahsilatRatingFunc({ rating_id: data.rating_id });
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const referencePaymentsFunc = (data) => {
    managementService
      .reference_payments(data)
      .then((response) => {
        setReferencePayments(response);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const referencePayedFunc = (data) => {
    managementService
      .reference_payed(data)
      .then((response) => {
        let data = {
          payed: 0,
        };
        referencePaymentsFunc(data);
        success("İşlem gerçekleşti", 4500);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const getMultiProductDiscounts = () => {
    managementService
      .get_multi_product_discounts()
      .then((response) => {
        setMultiProductsDiscounts(response);
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  const updateMultiProductDiscounts = (data) => {
    managementService
      .update_multi_product_discounts(data)
      .then((response) => {
        getMultiProductDiscounts();
      })
      .catch((err) => {
        alert(err.response.data.message);
      });
  };

  return (
    <ManagementContext.Provider
      value={{
        officeUserCostsFunc,
        officeUserCosts,
        officeUserCostsChangeFunc,
        getPaidableUsers,
        primResult,
        filteredReports,
        fetchFilteredReports,
        managerWorksList,
        getManagerWorks,
        getPaymentTracker,
        paymentTracker,
        getGraphs,
        graphs,
        getApplicationTypes,
        applicationTypes,
        getServicesReportsRating,
        ratingsInfo,
        getRestaurantServices,
        restaurantServicesInfo,
        getRestaurantServicesGraphs,
        restaurantServicesGrahps,
        getOptionalProducts,
        optionalProducts,
        getDataResult,
        patchDataResult,
        dataResult,
        sellerUserListFunc,
        sellerUserList,
        getKpiOutbound,
        kpiOutbound,
        getKpiSetup,
        kpiSetup,
        getKpiCollector,
        kpiCollector,
        kpiTechSupports,
        getSetupCount,
        setupCount,
        getSmsSettings,
        sendSmsSetting,
        smsSettingsList,
        editSmsSetting,
        deleteSmsSetting,
        getCollectorData,
        collectorData,
        getKpiTickets,
        kpiTickets,
        getKpiStateHistory,
        kpiStateHistory,
        getCurrentList,
        currentList,
        managePrimSettings,
        primSettings,
        setPrimSettings,
        changePrimSettings,
        collectorDataRenew,
        dashboardOfficeuser,
        dashboardOfficeuserList,
        getCollectorDataRenew,
        dashboardOfficeuserDetail,
        dashboardOfficeuserDetailFunc,
        dashboardOfficeuserDetailDeleteReasons,
        dashboardOfficeuserDetailBlockedReasons,
        dashboardOfficeuserDetailApplicationTypes,
        deletedReasons,
        blockedReasons,
        officeUserApplicationTypes,
        dashboardOfficeuserDetailPayedOffers,
        payedOffers,
        dashboardOfficeuserDetailCompletedTasks,
        dashboardOfficeuserDetailCalendar,
        dashboardOfficeuserDetailTaskRepeats,
        dashboardOfficeuserDetailTaskRepeatsDates,
        officeUserDetailCalendar,
        officeUserDetailTaskRepeats,
        officeUserDetailCompletedTasks,
        taskRepeatDates,
        dashboardOfficeuserDetailActiveStatus,
        dashboardOfficeuserDetailTaskLock,
        dashboardOfficeuserDetailUserDelete,
        dashboardOfficeuserTransfer,
        dashboardOfficeuserRestofficials,
        dashboardUserRestofficials,
        dashboardOfficeuserTransferRestofficials,
        dashboardOfficeuserProductRenewals,
        dashboardProductRenewals,
        dashboardOfficeuserProductRenewalsRestaurants,
        dashboardProductRenewalsDetail,
        dashboardOfficeuserInfo,
        userInfo,
        setUserInfo,
        getDictionaryHeaders,
        addDictionaryHeaders,
        dictionaryHeaders,
        editDictionaryHeaders,
        deleteDictionaryHeaders,
        addDictionaryFeature,
        getDictionaryFeatures,
        dictionaryFeatures,
        setDictionaryFeatures,
        deleteDictionaryFeature,
        editDictionaryFeature,
        dashboardOfficeuserTech,
        dashboardOfficeuserTechDetailFunc,
        dashboardLoader,
        dashboardOfficeuserDetailCalendarTech,
        dashboardOfficeuserTechActiveTasks,
        officeUserDetailActiveTasks,
        dashboardOfficeuserTechTicketBounces,
        getCollectorTreansactionData,
        collectorTransactionData,
        financeOfferFunc,
        financeOffer,
        financeOfferChangeFunc,
        setFinanceOffer,
        accountingCurrentToKunyeFunc,
        createSmsTemplateFunc,
        smsTemplateListFunc,
        smsTemplateList,
        createSmsTemplateTestFunc,
        platformAndProductsFunc,
        platformAndProducts,
        publishSmsFunc,
        popupTemplateListFunc,
        popupTemplateList,
        createPopupTemplateFunc,
        buttonActionsListFunc,
        buttonActionsList,
        publishPopupFunc,
        bannerTemplateListFunc,
        createBannerTemplateFunc,
        bannerTemplateList,
        publishBannerFunc,
        deleteBannerTemplateFunc,
        bannerTemplateChangeFunc,
        bannerListFunc,
        bannerList,
        bannerChangeFunc,
        createPrintingPressUser,
        getPrintingPressList,
        printingPressList,
        openPrintUserModal,
        setOpenPrintUserModal,
        editPrintingPressUser,
        createNewBaski,
        getBaskiTypes,
        baskiTypes,
        editBaskiDetail,
        getPrintingRequestList,
        printingRequestList,
        getBrochurePicture,
        getPrintingRequestDetail,
        printingRequestDetail,
        editPrintingRequestDetail,
        getBaskiRequestsFinance,
        dailyPaymentListFunc,
        dailyPaymentList,
        dailyPaymentPatchFunc,
        dailyPaymentDeleteFunc,
        dailyPaymentExelFunc,
        brochurePicture,
        setBrochurePicture,
        actionPrintingRequestFinance,
        stateHistoryPastListFunc,
        stateHistoryPastList,
        createPrintingRequest,
        brochureLoading,
        brochurePictureDownload,
        brochureDownloadLoading,
        getProductPrimSettings,
        selectedProductPrimSettings,
        setSelectedProductPrimSettings,
        sendPrimSettings,
        getOfferDetail,
        offerDetail,
        setOfferDetail,
        brochureSendRestaurant,
        getExtraPrimSettings,
        extraPrimSettings,
        setExtraPrimSettings,
        addExtraPrimSettings,
        brochureResResult,
        setBrochureResResult,
        createOfficeUser,
        getUserRoles,
        getUserSubdepartments,
        getUserDepartments,
        userRoles,
        userDepartments,
        userSubdepartments,
        getOfficeUser,
        officeUserDetail,
        editOfficeUser,
        setOfficeUserDetail,
        showAddUserModal,
        setShowAddUserModal,
        dashboardCdrListFunc,
        dashboardCdrFiltersFunc,
        dashboardCdrList,
        dashboardCdrFilterList,
        dashboardCdrStatsFunc,
        dashboardCdrStatsList,
        ratingSettingsListFunc,
        ratingSettingsList,
        createRatingSettingsFunc,
        ratingListFunc,
        ratingListNew,
        setRatingListNew,
        ratingListEducation,
        setRatingListEducation,
        ratingListCompleted,
        setRatingListCompleted,
        puantajRecordFunc,
        ratingRateFunc,
        ratingEducationNoteFunc,
        santralRecordFunc,
        ratingSettingAddFunc,
        ratingSettingDeleteFunc,
        cdrRepeatsListFunc,
        cdrRepeatsList,
        cdrOutboundDurationListFunc,
        cdrOutboundDurationsList,
        cdrInboundDurationListFunc,
        cdrInboundDurationsList,
        tahsilatRatingFunc,
        tahsilatRatingList,
        setTahsilatRatingList,
        ratingRateTahsilatFunc,
        tahsilatRatingSkipFunc,
        referencePaymentsFunc,
        referencePayments,
        referencePayedFunc,
        getMultiProductDiscounts,
        multiProductsDiscounts,
        updateMultiProductDiscounts,
        setMultiProductsDiscounts,
      }}
    >
      {props.children}
    </ManagementContext.Provider>
  );
};

export default ManagementProvider;
