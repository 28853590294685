import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useMediaQuery,
  createTheme,
  IconButton,
  InputBase,
  Button,
  FormControlLabel,
  Checkbox,
  Badge,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  Tooltip,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { RestaurantContext } from "../../contexts/restaurantContext";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import InputField from "../../components/input";
import {
  SkipNext,
  SkipPrevious,
  Phone,
  Smartphone,
  Settings,
  CheckCircleOutline,
  HighlightOff,
  AddBox,
  ArrowCircleRight,
  Assignment,
  RemoveRedEye,
  PhonelinkOff,
  Call,
  AddCircleOutline,
} from "@mui/icons-material";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tr from "date-fns/locale/tr";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import CustomDatePickerInput from "../../components/datePickerInput";
import sepettakipLogo from "../../utils/assets/sepettakipLogos/sepettakip-logo.webp";
import sepetappLogo from "../../utils/assets/sepettakipLogos/sepetapp-logo.webp";
import sepetfastLogo from "../../utils/assets/sepettakipLogos/sepetfast-logo.webp";
import calleridLogo from "../../utils/assets/sepettakipLogos/callerid-logo.webp";
import courierLogo from "../../utils/assets/sepettakipLogos/courier-logo.webp";
import stoktakipLogo from "../../utils/assets/sepettakipLogos/stoktakip-logo.webp";
import masatakipLogo from "../../utils/assets/sepettakipLogos/masatakip-logo.webp";
import emptyPhoto from "../../utils/assets/emptyPhoto.jpg";
import uploadPhoto from "../../utils/assets/uploadPhoto.png";
import LottieMain from "../../components/lottie";
import Platforms from "./components/platform";
import FormatNumber from "../../components/formatData/formatPhone";
import UserNotesModal from "./modals/userNotes";
import { useHistory } from "react-router-dom";
import { useToast } from "../../components/snackbar";
import validator from "validator";
import { isBlank, validateEmail } from "../../components/regExr";
import ApplicationActions from "../../components/modals/applicationActions";
import { selectDefaultStyles } from "../../components/selectStyles/stylev4";
import RestPrevOfferHistoryModal from "../../components/modals/restPrevOfferHistory";
import { offerCount } from "../../utils/constants";
import { addDays } from "date-fns";
import ActionModal from "../../components/modals/actionsModal";
import useDebounce from "../../components/debounce";
import SearchV2 from "../../components/search/searchv2";
import LockedModal from "./modals/lockedModal";
import PhoneInput from "react-phone-input-2";
import PaidIcon from "@mui/icons-material/Paid";
import PayedModal from "../../components/modals/payedModal";
import InfoIcon from "@mui/icons-material/Info";
import SearchWithIcon from "../../components/searchWithIcon";
import BlockPotetialModal from "../../components/modals/blockPotential";
import restaurantService from "../../services/restaurantService";
import OfferPriceChangeModal from "../../components/modals/offerPriceChange";
import NotPayedModal from "./modals/notPayed";
import OfferModalV2 from "../../components/modals/offerModal";
import ReminderModal from "./modals/reminderModal";
import LimitedUseModal from "../../components/modals/limitedUseModal";
import ProductPhotoModal from "../restaurants/managementPanel/modals/productPhotoModal";
import ProductPoolModal from "../restaurants/managementPanel/modals/productPoolModal";
import { useSnackbar } from "notistack";
import { CallContext, callkey } from "../../contexts/callContext";
import makeCall from "../../components/call/func/makeCall";
import ProductProfitModal from "./modals/productProfit";
import ReferencePhoneModal from "./modals/referencePhone";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import OTPInput from "react-otp-input";

registerLocale("tr", tr);

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderRadius: 10,
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "gray",
      borderWidth: 0,
      borderRadius: 10,
    },
    "& .MuiOutlinedInput-input": {
      marginTop: 6,
      marginLeft: 6,
      color: "black",
      borderRadius: 10,
    },
    "& .css-1wu7ecg-MuiSvgIcon-root-MuiSelect-icon": {
      color: "black",
    },
  },
});

const Tasks = () => {
  let history = useHistory();
  const classes = useStyles();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    getTaskList,
    taskList,
    newTaskCreateFunc,
    cities,
    town,
    getTown,
    neigh,
    getNeighborhood,
    foodCategory,
    getSectorListFunc,
    sectorList,
    taskListDetailFunc,
    calendarListFunc,
    taskActionFunc,
    calendarList,
    personalTemplateNoteListFunc,
    personalTemplateNoteList,
    calendarAllListFunc,
    calendarAllList,
    getCity,
    getFoodCategory,
    taskRevertFunc,
    patchRestOfficialFunc,
    getTrackerNotes,
    trackerNotes,
    postTrackerNotes,
    taskApplicationUpdateFunc,
    applicationNotesFunc,
    applicationNotesCreateFunc,
    applicationNotes,
    blockedReasons,
    blockedStatusReasonsFunc,
    getTicketTypes,
    ticketTypes,
    taskPhoneSearchFunc,
    taskResetFunc,
    userStatus,
    getApplicationTransferList,
    getActiveTasks,
    activeTasks,
    backofficeList,
    taskFromCalendarFunc,
    taskPullFunc,
    sendOfferPopupFunc,
    patchLimitedUseTask,
    showProductProfitFunc,
    setSwingAnim,
    offerUnlockFunc,
  } = useContext(RestaurantContext);
  const { cdrMissedCount } = useContext(CallContext);
  const [taskType, setTaskType] = useState(null);
  const [formData, setFormData] = useState(null);
  const [note, setNote] = useState("");
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [potentialProducts, setPotentialProducts] = useState([]);
  const [potentialProductsApp, setPotentialProductsApp] = useState([]);
  const { alert } = useToast();
  const [applicationActionsModal, setApplicationActionsModal] = useState(false);
  const [applicationId, setApplicationId] = useState(null);
  const [pageStatus, setPageStatus] = useState("home");
  const [applicationTypes, setApplicationTypes] = useState(null);
  const [selectedDeleteReason, setSelectedDeleteReason] = useState(null);
  const [restPrevOfferHistoryModal, setRestPrevOfferHistoryModal] =
    useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [restCheckActiveOffer, setRestCheckActiveOffer] = useState(null);
  const [selectedTicketType, setSelectedTicketType] = useState(null);
  const [actionModal, setActionModal] = useState(false);
  const [actionModalResId, setActionModalResId] = useState(null);
  const [lockedModal, setLockedModal] = useState(false);
  const [currentRowForUnlock, setCurrentRowForUnlock] = useState(null);
  const [lockTask, setLockTask] = useState(false);
  const [payedModal, setPayedModal] = useState("");
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [searchTermCalendar, setSearchTermCalendar] = useState("");
  const [blockPotentialModal, setBlockPotentialModal] = useState(false);
  const [selectedPotentialType, setSelectedPotentialType] = useState(null);
  const [offerPriceChangeModal, setOfferPriceChangeModal] = useState(false);
  const [financeOfferRow, setFinanceOfferRow] = useState(null);
  const [notPayedModal, setNotPayedModal] = useState(false);
  const [offerModal, setOfferModal] = useState(false);
  const [reminderModal, setReminderModal] = useState(false);
  const [reminderRow, setReminderRow] = useState(null);
  const [limitedUseModal, setLimitedUseModal] = useState(false);
  const [productPhotoModal, setProductPhotoModal] = useState(false);
  const [openProductPoolModal, setOpenProductPoolModal] = useState(false);
  const [disabledCallIcon, setDisabledCallIcon] = useState(false);
  const [currentProfitRestId, setCurrentProfitRestId] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);

  const userDetail = JSON.parse(localStorage.getItem("st-officeuser-info"));

  const [startDate, setStartDate] = useState(
    setHours(
      setMinutes(new Date(), moment().format("mm")),
      moment().format("HH")
    )
  );
  const [userNotesModal, setUserNotesModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [productProfitModal, setProductProfitModal] = useState(false);
  const [referencePhoneModal, setReferencePhoneModal] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [lowerPaymentPdf, setLowerPaymentPdf] = useState(null);
  const [passw, setPassw] = useState("");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const {
    cdrOutboundFunc,
    cdrAnswerFunc,
    cdrHangupFunc,
    phoneNumberv2,
    cdrMissedCountFunc,
  } = useContext(CallContext);

  useEffect(() => {
    getCity();
    getFoodCategory();
    getTaskList();
    calendarListFunc();
    personalTemplateNoteListFunc();
    calendarAllListFunc();
    blockedStatusReasonsFunc();
    if (userDetail?.role === "tech") {
      getActiveTasks();
    }
  }, []);

  useEffect(() => {
    if (userStatus !== null) {
      if (userStatus === "out_call" || userStatus === "setup") {
        setLockTask(true);
      } else {
        setLockTask(false);
      }
    }
  }, [userStatus]);

  useEffect(() => {
    if (selectedPotentialType !== null) {
      if (selectedPotentialType !== "potential") {
        restaurantService.patch_restofficial({
          id: taskList?.restofficial?.user_id,
          sendObj: {
            block_potential: false,
          },
        });
      }

      let data = {
        task_id: taskList?.id,
        tag: selectedHeader?.tag,
        sendObj: {
          note,
          action: selectedHeader?.id,
        },
      };
      taskActionFunc(data);
      setNote("");
      setSelectedHeader(null);
      setStartDate(
        setHours(
          setMinutes(new Date(), moment().format("mm")),
          moment().format("HH")
        )
      );
      setBlockPotentialModal(false);
      setSelectedTicketType(null);
    }
  }, [selectedPotentialType]);

  useEffect(() => {
    if (selectedHeader !== null && selectedHeader?.tag === "tech") {
      getTicketTypes("tech");
    }

    if (selectedHeader !== null && selectedHeader?.tag === "create_ticket") {
      getApplicationTransferList();
    }
  }, [selectedHeader]);

  const sendSearchTermFunc = () => {
    if (debouncedSearchTerm?.length !== 0) {
      taskPhoneSearchFunc({
        sendObj: {
          phone: debouncedSearchTerm,
        },
      });
    } else {
      alert("arama boş olamaz");
    }
  };

  useEffect(() => {
    if (taskList !== null) {
      if (
        taskList?.restofficial_reminder &&
        Object.keys(taskList?.restofficial_reminder)?.length !== 0
      ) {
        setReminderRow(taskList?.restofficial_reminder);
        setReminderModal(true);
      }

      if (taskList?.locked) {
        setLockedModal(true);
        setCurrentRowForUnlock(taskList);
      }
      if (taskList?.application !== null) {
        setTaskType("application");
        setFormData(taskList?.application);
        getSectorListFunc();
        taskList?.application?.potential_products?.map((row) => {
          if (row?.potential) {
            potentialProductsApp.push(row?.name);
          }
        });
      } else {
        setTaskType("restofficial");
        taskList?.restofficial?.potential_products?.map((row) => {
          if (row?.potential) {
            potentialProducts.push(row?.name);
          }
        });
        let checkOffer = taskList?.restofficial?.offers?.some((row) => {
          return row?.payed === false;
        });
        setRestCheckActiveOffer(checkOffer);
      }
    }
  }, [taskList]);

  useEffect(() => {
    if (formData?.town != null) {
      getNeighborhood(formData?.city, formData?.town);
    }
  }, [formData?.town]);

  useEffect(() => {
    if (formData?.city != null) {
      getTown(formData?.city);
    }
  }, [formData?.city]);

  const handleChange = (name) => (event) => {
    if (name == "city") {
      getTown(event.target.value);
    } else if (name == "town") {
      getNeighborhood(formData.city, event.target.value);
    }
    let valueData = event.target ? event.target.value : event;
    let newData = { ...formData };
    newData = { ...formData, [name]: valueData };
    setFormData(newData);
  };

  const sendRegistration = (id) => {
    if (isBlank(formData?.restaurant_name)) {
      alert("Restoran adı boş bırakılamaz.");
    } else if (formData?.restaurant_name === "TBD IVR") {
      alert("Restoran ismi uygun değil.");
    } else if (isBlank(formData?.full_name)) {
      alert("Yetkili adı boş bırakılamaz.");
    } else if (formData?.foodcategory == null) {
      alert("Mutfak tipi boş bırakılamaz.");
    } else if (formData?.sector == null) {
      alert("Sektör tipi boş bırakılamaz.");
    } else if (!validator.isMobilePhone(formData?.phone, "tr-TR")) {
      alert("Uygun bir cep telefonu giriniz.");
    } else if (isBlank(formData?.landline_phone)) {
      alert("Sabit telefon boş bırakılamaz.");
    } else if (!validateEmail(formData?.email)) {
      alert("Uygun bir email giriniz.");
    } else if (formData?.city == null) {
      alert("İl seçimi boş bırakılamaz.");
    } else if (formData?.town == null) {
      alert("İlçe seçimi boş bırakılamaz.");
    } else if (formData?.neighborhood == null) {
      alert("Mahalle seçimi boş bırakılamaz.");
    } else if (isBlank(formData?.street)) {
      alert("Cadde / Sokak boş bırakılamaz.");
    } else if (isBlank(formData?.door_number)) {
      alert("Kapı numarası boş bırakılamaz.");
    } else if (isBlank(formData?.latitude)) {
      alert("Latitude alanı boş bırakılamaz.");
    } else if (isBlank(formData?.longitude)) {
      alert("Longitude alanı boş bırakılamaz.");
    } else {
      formData.potential_products = potentialProductsApp;
      if (formData.reference !== null) {
        formData.reference = formData.reference.user_id;
      }
      let data = {
        task_id: taskList?.id,
        app_id: taskList?.application?.id,
        sendObj: formData,
      };
      taskApplicationUpdateFunc(data);
    }
  };

  function countAndShowOccurrences(array) {
    const resultObject = {};

    array.forEach((item) => {
      const { title } = item;
      if (resultObject[title]) {
        resultObject[title].count++;
      } else {
        resultObject[title] = { count: 1 };
      }
    });
    const resultArray = Object.entries(resultObject).map(
      ([title, { count }]) => ({ title, count })
    );

    return resultArray;
  }

  const calcWeeklyOrderStatus = () => {
    let totalCount = 0;
    let totalAmount = 0;
    taskList?.restofficial?.stats?.product_stats?.map((row) => {
      totalCount = totalCount + row?.total_count;
      totalAmount = totalAmount + row?.total_amount;
    });

    return `Sipariş Adeti : ${totalCount} | Toplam Tutar : ${totalAmount} ₺`;
  };

  let restId;
  if (taskList?.restofficial?.user_id) {
    restId = taskList?.restofficial?.user_id;
  }

  useEffect(() => {
    if (selectedPerson !== null) {
      getTicketTypes(selectedPerson?.role);
      setSelectedTicketType(null);
    }
  }, [selectedPerson]);

  const restaurantLimitedUse = () => {
    patchLimitedUseTask(taskList?.restofficial?.user_id, {
      limited_use: !taskList?.restofficial?.limited_use,
    });
  };

  const onChangePdf = (e) => {
    setLowerPaymentPdf(e.target.files[0]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        {taskType === null ? (
          <div
            style={{
              display: "flex",
              height: "80%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LottieMain />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "black",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "98.7%",
                height: "10%",
                backgroundColor:
                  taskList?.restofficial !== null ||
                  taskList?.task_type.tag === "product_picture"
                    ? "#448D8C"
                    : "#C50C0C",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flex: 1,
                  justifyContent: "space-between",
                }}
              >
                <Paper
                  elevation={2}
                  style={{
                    width: 150,
                    backgroundColor:
                      taskList?.restofficial !== null ? "#197372" : "#AB1818",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    setNote("");
                    if (taskList?.previous_task) {
                      taskListDetailFunc({
                        task_id: taskList?.previous_task,
                      });
                    }
                  }}
                >
                  <SkipPrevious
                    style={{ width: 60, height: 60, color: "#fff" }}
                  />
                </Paper>
                {taskList?.restofficial !== null && (
                  <div
                    style={{
                      marginLeft: 20,
                      color: "#fff",
                      letterSpacing: 0.8,
                      fontFamily: "segoeuiB",
                      display: "flex",
                      textAlign: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 18,
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        localStorage.setItem("offers", "kunye");
                        history.push(
                          `/restaurants/${taskList?.restofficial?.user_id}`
                        );
                      }}
                    >
                      {taskList?.restofficial?.name}
                    </div>
                    <div style={{ fontSize: 13 }}>
                      {taskList?.restofficial?.city} /{" "}
                      {taskList?.restofficial?.town} /{" "}
                      {moment(taskList?.restofficial?.created_at).format(
                        "DD-MM-YYYY"
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div
                style={{
                  color: "#fff",
                  letterSpacing: 0.8,
                  fontFamily: "segoeuiB",
                  fontSize: 32,
                  flex: 2,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {taskList?.task_type?.name}{" "}
                {taskList?.ticket !== null
                  ? taskList?.ticket?.ticket_type?.ticket_type !==
                    "Restoran Teknik Destek"
                    ? " - " + taskList?.ticket?.ticket_type?.ticket_type
                    : ""
                  : ""}
                {taskList?.ticket?.created_by && (
                  <Tooltip
                    title={
                      <div>
                        <div>
                          Oluşturan: {taskList?.ticket?.created_by?.full_name}
                        </div>
                        <div>
                          Oluşturulan Tarih:{" "}
                          {moment(taskList?.ticket?.created_at).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </div>
                        <div>Not: {taskList?.ticket?.creation_note}</div>
                      </div>
                    }
                  >
                    <InfoIcon />
                  </Tooltip>
                )}
                {taskList?.calendar_task && " - Randevu"}
                {taskList?.task_type?.tag !== "roi" &&
                  taskList?.title !== "" && (
                    <div style={{ fontSize: 20, color: "cornsilk" }}>
                      ( {taskList?.title}{" "}
                      {taskList?.application !== null &&
                        taskList?.application?.reference !== null && (
                          <span>
                            - Referans:"
                            {
                              <span
                                onClick={() => {
                                  history.push(
                                    `/restaurants/${taskList?.application?.reference?.user_id}`
                                  );
                                }}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                {taskList?.application?.reference?.name}
                              </span>
                            }
                            "{" "}
                          </span>
                        )}
                      )
                    </div>
                  )}
                {taskList?.completed && (
                  <div
                    style={{
                      backgroundColor: "green",
                      padding: 2,
                      borderRadius: 4,
                      paddingLeft: 2,
                      paddingRight: 2,
                      marginLeft: 10,
                    }}
                  >
                    Tamamlandı
                  </div>
                )}
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "45%" }}>
                  <SearchV2
                    searchTerm={searchTerm}
                    placeTitle={"Restoran Ara"}
                    setSearchTerm={setSearchTerm}
                    sendSearchTermFunc={sendSearchTermFunc}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Paper
                    elevation={2}
                    style={{
                      width: 150,
                      backgroundColor:
                        taskList?.restofficial !== null ? "#197372" : "#AB1818",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      marginRight: 10,
                    }}
                    onClick={() => {
                      setNote("");
                      if (taskList?.next_task) {
                        taskListDetailFunc({
                          task_id: taskList?.next_task,
                        });
                      } else {
                        if (cdrMissedCount?.count === 0) {
                          newTaskCreateFunc();
                        } else {
                          setSwingAnim(true);
                          setTimeout(() => {
                            setSwingAnim(false);
                          }, 1500);
                        }
                      }
                    }}
                  >
                    <SkipNext
                      style={{ width: 60, height: 60, color: "#fff" }}
                    />
                  </Paper>
                </div>
              </div>
            </div>
            {lockTask ? (
              ""
            ) : (
              <>
                {taskList?.restofficial !== null ? (
                  <>
                    <div
                      style={{
                        width: "100%",
                        height: "13%",
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{
                          width: "32%",
                          backgroundColor: "#393737",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "90%",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: 2,
                                    width: 14,
                                    height: 14,
                                    backgroundColor: "red",
                                  }}
                                />
                                <div
                                  style={{
                                    marginLeft: 10,
                                    letterSpacing: 0.8,
                                    fontFamily: "segoeuiRg",
                                    fontSize: 14,
                                    color: "#fff",
                                  }}
                                >
                                  {taskList?.restofficial?.online_date !== null
                                    ? moment(
                                        taskList?.restofficial?.online_date
                                      ).fromNow() + " aktifti"
                                    : "Giriş yapılmamış"}
                                </div>
                              </div>
                            </div>

                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  display: "flex",
                                  backgroundColor: "lavender",
                                  borderRadius: 4,
                                  cursor: "pointer",
                                  padding: 4,
                                  marginTop: 4,
                                }}
                              >
                                Şube :
                                <span
                                  style={{ fontWeight: "bold", marginLeft: 3 }}
                                >
                                  {taskList?.restofficial?.branch_count}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  backgroundColor: "lavender",
                                  borderRadius: 4,
                                  cursor: "pointer",
                                  marginLeft: 10,
                                  padding: 4,
                                  marginTop: 4,
                                }}
                              >
                                Tabela :
                                <span
                                  style={{ fontWeight: "bold", marginLeft: 3 }}
                                >
                                  {taskList?.restofficial?.secret_key_count}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              letterSpacing: 0.8,
                              fontFamily: "segoeuiB",
                              fontSize: 24,
                              alignSelf: "center",
                              color: "#fff",
                            }}
                          >
                            {taskList?.restofficial?.full_name}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            width: "100%",
                            marginTop: 10,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              backgroundColor: "lavender",
                              borderRadius: 4,
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              if ("clipboard" in navigator) {
                                return await navigator.clipboard.writeText(
                                  taskList?.restofficial?.phone
                                );
                              } else {
                                return document.execCommand(
                                  "copy",
                                  true,
                                  taskList?.restofficial?.phone
                                );
                              }
                            }}
                          >
                            <div>
                              <Smartphone />
                            </div>
                            <div
                              style={{
                                marginLeft: 5,
                                letterSpacing: 0.8,
                                fontFamily: "segoeuiRg",
                                fontSize: 18,
                                color: disabledCallIcon ? "gray" : "black",
                              }}
                              onClick={() => {
                                setDisabledCallIcon(true);
                                setTimeout(() => {
                                  setDisabledCallIcon(false);
                                }, 5000);
                                if (!disabledCallIcon) {
                                  closeSnackbar(callkey);
                                  let phoneNumberv2Obj = Object.assign(
                                    "",
                                    JSON.parse(
                                      JSON.stringify({ ...phoneNumberv2 })
                                    )
                                  );
                                  phoneNumberv2Obj =
                                    taskList?.restofficial?.phone?.replace(
                                      /\s+/g,
                                      ""
                                    );
                                  phoneNumberv2Obj =
                                    phoneNumberv2Obj.slice(-10);

                                  phoneNumberv2Obj =
                                    (JSON.parse(
                                      localStorage.getItem("st-officeuser-info")
                                    )?.iqcell_verimor
                                      ? "0"
                                      : "90") + phoneNumberv2Obj;

                                  // phoneNumberv2Obj ="0013237461168"

                                  let sendCallData = {
                                    callee: phoneNumberv2Obj,
                                    trigger_source: "task",
                                    task: taskList?.id,
                                    restofficial:
                                      taskList?.restofficial?.user_id,
                                  };

                                  makeCall({
                                    enqueueSnackbar,
                                    closeSnackbar,
                                    sendCallData,
                                    cdrOutboundFunc,
                                    cdrAnswerFunc,
                                    cdrHangupFunc,
                                    callkey,
                                    cdrMissedCountFunc,
                                  });
                                }
                              }}
                            >
                              {FormatNumber(taskList?.restofficial?.phone, "")}
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              backgroundColor: "lavender",
                              borderRadius: 4,
                              cursor: "pointer",
                            }}
                            onClick={async () => {
                              if ("clipboard" in navigator) {
                                return await navigator.clipboard.writeText(
                                  taskList?.restofficial?.constant_phone
                                );
                              } else {
                                return document.execCommand(
                                  "copy",
                                  true,
                                  taskList?.restofficial?.constant_phone
                                );
                              }
                            }}
                          >
                            <div>
                              <Phone />
                            </div>
                            <div
                              style={{
                                marginLeft: 5,
                                letterSpacing: 0.8,
                                fontFamily: "segoeuiRg",
                                fontSize: 18,
                                color: disabledCallIcon ? "gray" : "black",
                              }}
                              onClick={() => {
                                setDisabledCallIcon(true);
                                setTimeout(() => {
                                  setDisabledCallIcon(false);
                                }, 5000);
                                if (!disabledCallIcon) {
                                  closeSnackbar(callkey);
                                  let phoneNumberv2Obj = Object.assign(
                                    "",
                                    JSON.parse(
                                      JSON.stringify({ ...phoneNumberv2 })
                                    )
                                  );

                                  phoneNumberv2Obj =
                                    taskList?.restofficial?.constant_phone?.replace(
                                      /\s+/g,
                                      ""
                                    );
                                  phoneNumberv2Obj =
                                    phoneNumberv2Obj.slice(-10);
                                  phoneNumberv2Obj =
                                    (JSON.parse(
                                      localStorage.getItem("st-officeuser-info")
                                    )?.iqcell_verimor
                                      ? "0"
                                      : "90") + phoneNumberv2Obj;

                                  let sendCallData = {
                                    callee: phoneNumberv2Obj,
                                    trigger_source: "task",
                                    task: taskList?.id,
                                    restofficial:
                                      taskList?.restofficial?.user_id,
                                  };

                                  makeCall({
                                    enqueueSnackbar,
                                    closeSnackbar,
                                    sendCallData,
                                    cdrOutboundFunc,
                                    cdrAnswerFunc,
                                    cdrHangupFunc,
                                    callkey,
                                    cdrMissedCountFunc,
                                  });
                                }
                              }}
                            >
                              {FormatNumber(
                                taskList?.restofficial?.constant_phone,
                                ""
                              )}
                            </div>
                          </div>
                          {taskList?.task_type?.tag === "roi" ? (
                            <AddCircleOutline
                              onClick={() => {
                                setReferencePhoneModal(true);
                                setCurrentTask(taskList);
                              }}
                              style={{ color: "#fff", cursor: "pointer" }}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "32%",
                          backgroundColor: "#393737",
                        }}
                      >
                        {userDetail?.role !== "tech" ? (
                          <>
                            <div
                              style={{
                                margin: 10,
                                letterSpacing: 0.8,
                                fontFamily: "segoeuiB",
                                color: "#fff",
                              }}
                            >
                              Aktiflik
                            </div>
                            {taskList?.restofficial?.online ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: 2,
                                    width: 28,
                                    height: 28,
                                    backgroundColor: "#18cd18",
                                  }}
                                />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: 2,
                                    width: 28,
                                    height: 28,
                                    backgroundColor: "red",
                                  }}
                                />
                                <div
                                  style={{
                                    marginLeft: 10,
                                    letterSpacing: 0.8,
                                    fontFamily: "segoeuiRg",
                                    fontSize: 18,
                                    color: "#fff",
                                  }}
                                >
                                  {taskList?.restofficial?.online_date !== null
                                    ? moment(
                                        taskList?.restofficial?.online_date
                                      ).fromNow() + " aktifti"
                                    : "Giriş yapılmamış"}
                                </div>
                              </div>
                            )}
                          </>
                        ) : (
                          <div
                            style={{
                              overflow: "auto",
                              height: "90%",
                            }}
                          >
                            {taskList?.other_restofficial_tasks?.map(
                              (task, ind) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      color: "#fff",
                                      borderBottom:
                                        "1px solid rgba(255,255,255,0.4)",
                                      padding: 5,
                                      boxShadow:
                                        taskList?.id === task?.id
                                          ? "inset #208708 -1px 0px 5px 3px"
                                          : "none",
                                    }}
                                  >
                                    <div
                                      style={{ display: "flex", fontSize: 12 }}
                                    >
                                      <div style={{ marginRight: "5px" }}>
                                        {task?.type === "new_ticket"
                                          ? task.ticket_type
                                          : task.task_type}{" "}
                                        |
                                      </div>
                                      <div style={{ marginRight: "5px" }}>
                                        {task.type === "active_task"
                                          ? "Aktif"
                                          : task.type === "calendar"
                                          ? "Takvimde"
                                          : "Yeni"}{" "}
                                        |
                                      </div>
                                      <div>{task.officeuser?.full_name}</div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginRight: 10,
                                          fontSize: 12,
                                        }}
                                      >
                                        {moment(task.created_at).fromNow()}
                                      </div>

                                      <Button
                                        style={{
                                          fontFamily: "segoeuiB",
                                          display: "flex",
                                          justifyContent: "center",
                                          height: 25,
                                          background: "#448D8C",
                                          color: "#fff",
                                          minWidth: 90,
                                        }}
                                        size="small"
                                        onClick={() => {
                                          if (
                                            userDetail?.user_id ===
                                              task?.officeuser?.user_id &&
                                            task?.type === "active_task"
                                          ) {
                                            taskListDetailFunc({
                                              task_id: task.id,
                                            });
                                          } else {
                                            taskPullFunc({
                                              id: task?.id,
                                              type: task?.type,
                                            });
                                          }
                                        }}
                                      >
                                        {userDetail?.user_id ===
                                          task?.officeuser?.user_id &&
                                        (task?.type === "active_task" ||
                                          task?.type === "calendar")
                                          ? "Göreve git"
                                          : "Taskı Al"}
                                      </Button>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )}
                      </div>
                      <Paper
                        elevation={2}
                        style={{
                          width: "32%",
                          backgroundColor: "#393737",
                          borderRadius: 0,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              margin: 10,
                              letterSpacing: 0.8,
                              fontFamily: "segoeuiB",
                              color: "#fff",
                            }}
                          >
                            Platformlar
                          </div>
                          <div
                            style={{
                              marginTop: 10,
                              display: "flex",
                              marginRight: 10,
                            }}
                          >
                            <Settings
                              onClick={() => {
                                localStorage.setItem("offers", "platforms");
                                history.push(
                                  `/restaurants/${taskList?.restofficial?.user_id}`
                                );
                              }}
                              onMouseDown={(event) => {
                                if (event.button === 1) {
                                  localStorage.setItem("offers", "platforms");
                                  window.open(
                                    `${window.location.origin}/restaurants/${taskList?.restofficial?.user_id}`,
                                    "_blank"
                                  );
                                }
                              }}
                              style={{ cursor: "pointer", color: "#fff" }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          {taskList?.restofficial?.platforms?.map((row1) => {
                            if (row1?.name === "CallerID") return;
                            return (
                              <React.Fragment key={row1?.id}>
                                <Platforms
                                  restaurantInfo={row1}
                                  checkPlatform={row1?.name}
                                />
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </Paper>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        height:
                          taskList?.task_type.tag === "product_picture"
                            ? "30%"
                            : "45%",
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "space-around",
                        marginTop: 10,
                      }}
                    >
                      {taskList?.task_type.tag === "product_picture" ? (
                        <>
                          <div
                            style={{
                              display: "flex",
                              marginLeft: 10,
                              marginTop: 10,
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                backgroundColor: "#fff",
                                width: "80%",
                                alignItems: "center",
                                paddingLeft: 20,
                              }}
                              onClick={() => {}}
                            >
                              <img
                                style={{
                                  height: 150,
                                  width: 150,
                                  borderRadius: 10,
                                  border: "1px solid darkgray",
                                }}
                                alt="empty"
                                src={emptyPhoto}
                              />
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "space-evenly",
                                  marginLeft: 15,
                                  fontSize: 20,
                                  fontWeight: "bold",
                                }}
                              >
                                <span>
                                  {taskList?.title === "Başlık"
                                    ? taskList?.product_header_name
                                    : taskList?.product_name}
                                </span>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#fff",
                                marginLeft: 10,
                                width: "20%",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: 100,
                                    minWidth: 100,
                                    height: "100%",
                                  }}
                                >
                                  <img
                                    onClick={() => {
                                      setProductPhotoModal(true);
                                    }}
                                    style={{
                                      width: "80%",
                                      height: "80%",
                                      borderRadius: 10,
                                      cursor: "pointer",
                                    }}
                                    src={uploadPhoto}
                                    alt="uploadPhoto"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {" "}
                          <div
                            style={{
                              width: "32%",
                              backgroundColor: "#393737",
                              borderRadius: 4,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                height: "50%",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height: "11%",
                                  backgroundColor: "#448D8C",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  color: "#fff",
                                  marginBottom: 10,
                                  letterSpacing: 0.8,
                                  fontFamily: "segoeuiB",
                                }}
                              >
                                <div></div>
                                <div>TASK'A AİT NOTLAR</div>
                                <div
                                  style={{ marginRight: 10, display: "flex" }}
                                ></div>
                              </div>
                              <div
                                style={{
                                  backgroundColor: "#F0F0F0",
                                  height: "70%",
                                  borderRadius: 4,
                                  display: "flex",
                                  flexDirection: "column",
                                  overflow: "auto",
                                  boxShadow:
                                    taskList?.calendar_note_history?.length !==
                                    0
                                      ? "#208708 0px 0px 20px 9px"
                                      : "none",
                                }}
                              >
                                {taskList?.calendar_note_history?.map(
                                  (row, i) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          borderBottomStyle: "solid",
                                          borderBottomWidth: 1,
                                          borderBottomColor: "#f4f4f4",
                                        }}
                                      >
                                        <div
                                          key={i}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "row",
                                            width: "80%",
                                          }}
                                        >
                                          <li
                                            style={{
                                              marginTop: 4,
                                              marginLeft: 4,
                                              fontFamily: "segoeuiRg",
                                              fontSize: 14,
                                              color: "#000",
                                            }}
                                          >
                                            [{row.title}] : {row?.note}
                                          </li>
                                        </div>
                                        <div
                                          style={{
                                            fontFamily: "segoeuiRg",
                                            fontSize: 11,
                                            width: "20%",
                                            justifyContent: "center",
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <div>
                                            {moment(row?.created_at).format(
                                              "DD-MM-YYYY HH:mm"
                                            )}
                                          </div>
                                          <div>
                                            {moment(row?.created_at).fromNow()}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>

                            <div
                              style={{
                                height: "50%",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  height:
                                    userDetail?.role === "tech" ? "11%" : "8%",
                                  backgroundColor: "#448D8C",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  color: "#fff",
                                  marginBottom: 10,
                                  letterSpacing: 0.8,
                                  fontFamily: "segoeuiB",
                                }}
                              >
                                <div></div>
                                <div>NOTLAR</div>
                                <div
                                  style={{ marginRight: 10, display: "flex" }}
                                >
                                  <Settings
                                    onClick={() => {
                                      setActionModal(true);
                                      setActionModalResId({
                                        user_id:
                                          taskList?.restofficial?.user_id,
                                      });
                                    }}
                                    onMouseDown={(event) => {
                                      if (event.button === 1) {
                                        setActionModal(true);
                                        setActionModalResId({
                                          user_id:
                                            taskList?.restofficial?.user_id,
                                        });
                                      }
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </div>
                              <div></div>
                              <div
                                style={{
                                  backgroundColor: "#F0F0F0",
                                  height:
                                    userDetail?.role === "tech" ? "80%" : "90%",
                                  display: "flex",
                                  flexDirection: "column",
                                  overflow: "auto",
                                }}
                              >
                                {taskList?.restofficial?.notes?.map(
                                  (row, i) => {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          borderBottomStyle: "solid",
                                          borderBottomWidth: 1,
                                          borderBottomColor: "#f4f4f4",
                                        }}
                                      >
                                        <div
                                          key={i}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "row",
                                            width: "80%",
                                          }}
                                        >
                                          <li
                                            style={{
                                              marginTop: 4,
                                              marginLeft: 4,
                                              fontFamily: "segoeuiRg",
                                              fontSize: 14,
                                              color: "#000",
                                            }}
                                          >
                                            [{row.status}] [
                                            {row.created_by === null
                                              ? "-"
                                              : row?.created_by?.role ===
                                                "setup"
                                              ? "Kurulum"
                                              : row?.created_by?.role ===
                                                "collector"
                                              ? "Tahsilat"
                                              : row?.created_by?.role}
                                            ] [
                                            {row.created_by === null
                                              ? "-"
                                              : row?.created_by?.full_name}
                                            ] : {row?.note}
                                          </li>
                                        </div>
                                        <div
                                          style={{
                                            fontFamily: "segoeuiRg",
                                            fontSize: 11,
                                            width: "20%",
                                            justifyContent: "center",
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <div>
                                            {moment(row?.created_at).format(
                                              "DD-MM-YYYY HH:mm"
                                            )}
                                          </div>
                                          <div>
                                            {moment(row?.created_at).fromNow()}
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "32%",
                              backgroundColor: "#393737",
                              borderRadius: 4,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            {userDetail?.role === "tech" ? (
                              <div style={{ height: "100%" }}>
                                <div
                                  style={{
                                    width: "100%",
                                    backgroundColor: "#448D8C",
                                    color: "#fff",
                                    marginBottom: 10,
                                    letterSpacing: 0.8,
                                    fontFamily: "segoeuiB",
                                    textAlign: "center",
                                  }}
                                >
                                  AKTİF GÖREVLER
                                </div>
                                <div
                                  style={{
                                    overflow: "auto",
                                    height: "calc(100% - 40px)",
                                  }}
                                >
                                  {activeTasks.map((task) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        color: "#fff",
                                        marginBottom: 5,
                                        paddingBottom: 5,
                                        borderBottom:
                                          "1px solid rgba(255,255,255,0.4)",
                                        paddingLeft: 5,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          fontSize: 12,
                                        }}
                                      >
                                        <div style={{ marginRight: "5px" }}>
                                          {task.task_type.name} |
                                        </div>
                                        <div>{task.restofficial.name}</div>
                                      </div>

                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            marginRight: 10,
                                            fontSize: 12,
                                          }}
                                        >
                                          {moment(task.created_at).fromNow()}
                                        </div>

                                        <Button
                                          style={{
                                            fontFamily: "segoeuiB",
                                            display: "flex",
                                            justifyContent: "center",
                                            height: 25,
                                            background:
                                              task.task_type.name ===
                                              "Restoran Teknik Destek"
                                                ? "red"
                                                : "#448D8C",
                                            color: "#fff",
                                          }}
                                          size="small"
                                          onClick={() => {
                                            taskListDetailFunc({
                                              task_id: task.id,
                                            });
                                          }}
                                        >
                                          Göreve git
                                        </Button>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            ) : (
                              <>
                                {taskList?.restofficial?.offers.find(
                                  (row) => row.locked
                                ) !== undefined ? (
                                  <div
                                    style={{
                                      height: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "#fff",
                                        marginBottom: 10,
                                        fontWeight: 600,
                                      }}
                                    >
                                      Restorana gelen sms'teki şifreyi giriniz
                                    </span>
                                    <OTPInput
                                      value={passw}
                                      inputType="password"
                                      onChange={(e) => {
                                        setPassw(e);
                                        if (e.length === 4) {
                                          let data = {
                                            offer_id:
                                              taskList?.restofficial?.offers.find(
                                                (row) => row.locked
                                              )?.id,
                                            sendObj: {
                                              lock_code: Number(e),
                                            },
                                          };
                                          offerUnlockFunc(data);
                                        }
                                      }}
                                      numInputs={4}
                                      shouldAutoFocus
                                      containerStyle={{
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "space-around",
                                      }}
                                      renderInput={(props) => (
                                        <input
                                          {...props}
                                          style={{
                                            width: 35,
                                            height: 35,
                                            textAlign: "center",
                                            fontSize: 18,
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        width: "100%",
                                        height: "8%",
                                        backgroundColor: "#448D8C",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        color: "#fff",
                                        letterSpacing: 0.8,
                                        fontFamily: "segoeuiB",
                                        position: "relative",
                                      }}
                                    >
                                      <div style={{ marginLeft: 10 }}>
                                        <PaidIcon
                                          onClick={() => setPayedModal(true)}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <div> TEKLİF / ÖDEMELER</div>
                                      <div
                                        style={{
                                          marginRight: 10,
                                          display: "flex",
                                        }}
                                      >
                                        <Settings
                                          onClick={() => {
                                            localStorage.setItem(
                                              "offers",
                                              "offers"
                                            );
                                            history.push(
                                              `/restaurants/${taskList?.restofficial?.user_id}`
                                            );
                                          }}
                                          onMouseDown={(event) => {
                                            if (event.button === 1) {
                                              localStorage.setItem(
                                                "offers",
                                                "offers"
                                              );
                                              window.open(
                                                `${window.location.origin}/restaurants/${taskList?.restofficial?.user_id}`,
                                                "_blank"
                                              );
                                            }
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        backgroundColor: "#393737",
                                        height: "90%",
                                        display: "flex",
                                        flexDirection: "column",
                                        boxShadow: restCheckActiveOffer
                                          ? "#f30000 0px 0px 20px 9px"
                                          : "none",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <TableContainer>
                                        <Table>
                                          <TableHead>
                                            <TableRow>
                                              <TableCell align="center">
                                                Ürün
                                              </TableCell>
                                              <TableCell align="center">
                                                Teklif
                                              </TableCell>
                                              <TableCell align="center">
                                                Tarih
                                              </TableCell>
                                              <TableCell align="center">
                                                İndirim
                                              </TableCell>
                                              <TableCell align="center">
                                                Uzatıldı
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {taskList?.restofficial?.offers?.map(
                                              (row, ind) => {
                                                return (
                                                  <TableRow key={ind}>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        backgroundColor:
                                                          row?.payed
                                                            ? "#f0f0f0"
                                                            : "#ED4444",
                                                      }}
                                                    >
                                                      {row.lower_payment_request_file && (
                                                        <PictureAsPdfIcon
                                                          style={{
                                                            color: "#fff",
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() => {
                                                            window.open(
                                                              row.lower_payment_request_file,
                                                              "_blank"
                                                            );
                                                          }}
                                                        />
                                                      )}
                                                      {row?.product_offers?.map(
                                                        (row1) => {
                                                          return (
                                                            <div>
                                                              {
                                                                row1?.product_name
                                                              }
                                                              ({row1?.amount}₺)
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        backgroundColor:
                                                          row?.payed
                                                            ? "#f0f0f0"
                                                            : "#ED4444",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection:
                                                            "column",
                                                        }}
                                                      >
                                                        <div>
                                                          {row?.total_amount}₺
                                                        </div>
                                                        <div>
                                                          {row?.payed
                                                            ? "Ödendi"
                                                            : row?.eft_amount >
                                                              0
                                                            ? `Eft : ${row?.eft_amount}`
                                                            : "-"}
                                                        </div>
                                                      </div>
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        backgroundColor:
                                                          row?.payed
                                                            ? "#f0f0f0"
                                                            : "#ED4444",
                                                      }}
                                                    >
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          flexDirection:
                                                            "column",
                                                        }}
                                                      >
                                                        <div>
                                                          {moment(
                                                            row?.created_at
                                                          ).format(
                                                            "DD-MM-YYYY"
                                                          )}
                                                        </div>
                                                        <div>
                                                          {moment(
                                                            row?.created_at
                                                          ).fromNow()}
                                                        </div>
                                                      </div>
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        backgroundColor:
                                                          row?.payed
                                                            ? "#f0f0f0"
                                                            : "#ED4444",
                                                      }}
                                                    >
                                                      -
                                                    </TableCell>
                                                    <TableCell
                                                      align="center"
                                                      style={{
                                                        backgroundColor:
                                                          row?.payed
                                                            ? "#f0f0f0"
                                                            : "#ED4444",
                                                      }}
                                                    >
                                                      {" "}
                                                      <div>
                                                        {moment(
                                                          row?.expires_at
                                                        ).format("DD-MM-YYYY")}
                                                      </div>
                                                    </TableCell>
                                                  </TableRow>
                                                );
                                              }
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Button
                                          onClick={() => {
                                            setRestPrevOfferHistoryModal(true);
                                            setCurrentRow(taskList);
                                          }}
                                          variant="contained"
                                          size="medium"
                                          style={{
                                            backgroundColor: "green",
                                            alignSelf: "flex-start",
                                            margin: 10,
                                          }}
                                        >
                                          Geçmiş Teklifler
                                        </Button>
                                        {taskList?.restofficial?.offers?.find(
                                          (obj) => !obj.payed
                                        ) && (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            {(taskList?.task_type?.tag ===
                                              "contract_renewal" ||
                                              taskList?.task_type?.tag ===
                                                "collection") && (
                                              <PhonelinkOff
                                                disabled
                                                style={{
                                                  marginRight: 50,
                                                  cursor:
                                                    taskList?.restofficial
                                                      ?.version >= "1.1.5"
                                                      ? "pointer"
                                                      : "default",
                                                  color:
                                                    taskList?.restofficial
                                                      ?.version >= "1.1.5"
                                                      ? taskList?.restofficial
                                                          ?.limited_use
                                                        ? "#f30000"
                                                        : "green"
                                                      : "gray",
                                                  width: 30,
                                                  height: 30,
                                                }}
                                                onClick={() => {
                                                  if (
                                                    taskList?.restofficial
                                                      ?.version >= "1.1.5"
                                                  ) {
                                                    setLimitedUseModal(true);
                                                  }
                                                }}
                                              />
                                            )}
                                            <RemoveRedEye
                                              style={{
                                                marginRight: 10,
                                                width: 30,
                                                height: 30,
                                                cursor: "pointer",
                                                color: "green",
                                              }}
                                              onClick={() => {
                                                setOfferModal(true);
                                              }}
                                            />
                                            <Button
                                              onClick={() => {
                                                let data = {
                                                  trigger_popup: true,
                                                };
                                                sendOfferPopupFunc(
                                                  taskList?.restofficial
                                                    ?.user_id,
                                                  data,
                                                  "task",
                                                  taskList?.id
                                                );
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "green",
                                                padding: "5px 10px 5px 10px",
                                                margin: 10,
                                                textTransform: "capitalize",
                                                fontFamily: "segoeuiRg",
                                              }}
                                            >
                                              Teklif Göster
                                            </Button>
                                          </div>
                                        )}
                                        {taskList?.price_update_allowed &&
                                          (taskList?.task_type?.tag ===
                                            "lower_payment_request" ||
                                            taskList?.task_type?.tag ===
                                              "block_request" ||
                                            taskList?.task_type?.tag ===
                                              "contract_renewal" ||
                                            taskList?.task_type?.tag ===
                                              "collection" ||
                                            taskList?.task_type?.tag ===
                                              "cross_sale") && (
                                            <Button
                                              onClick={() => {
                                                setOfferPriceChangeModal(true);
                                                setFinanceOfferRow(taskList);
                                              }}
                                              disabled={
                                                taskList?.restofficial
                                                  ?.version >= "1.1.6"
                                                  ? taskList?.restofficial?.offers?.find(
                                                      (obj) =>
                                                        obj.active && !obj.seen
                                                    )
                                                  : false
                                              }
                                              variant="contained"
                                              size="medium"
                                              style={{
                                                backgroundColor:
                                                  taskList?.restofficial
                                                    ?.version >= "1.1.6"
                                                    ? taskList?.restofficial?.offers?.find(
                                                        (obj) =>
                                                          obj.active &&
                                                          !obj.seen
                                                      )
                                                      ? "gray"
                                                      : "green"
                                                    : "green",
                                                alignSelf: "flex-start",
                                                margin: 10,
                                              }}
                                            >
                                              Fiyat Değiştir
                                            </Button>
                                          )}

                                        <Button
                                          onClick={() => {
                                            setProductProfitModal(true);
                                            setCurrentProfitRestId(
                                              taskList?.restofficial?.user_id
                                            );
                                          }}
                                          style={{
                                            color: "white",
                                            backgroundColor: "green",
                                            padding: "5px 10px 5px 10px",
                                            margin: 10,
                                            textTransform: "capitalize",
                                            fontFamily: "segoeuiRg",
                                          }}
                                        >
                                          Kazanç Göster
                                        </Button>

                                        {/* {taskList?.restofficial?.offers?.map((row) => {
                                    if (!row?.payed) {
                                      return (
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div style={{ marginRight: 20 }}>
                                            <Badge
                                              invisible={!row?.discount_popup}
                                              anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              badgeContent={
                                                row?.discount_popup_accepted ? (
                                                  <CheckCircleOutline
                                                    style={{
                                                      width: 20,
                                                      height: 20,
                                                      backgroundColor: "green",
                                                      borderRadius: 99,
                                                      color: "#fff",
                                                    }}
                                                  />
                                                ) : (
                                                  <HighlightOff
                                                    style={{
                                                      width: 20,
                                                      height: 20,
                                                      backgroundColor: "red",
                                                      borderRadius: 99,
                                                      color: "#fff",
                                                    }}
                                                  />
                                                )
                                              }
                                            >
                                              <Button
                                                variant="contained"
                                                size="medium"
                                                style={{
                                                  backgroundColor:
                                                    row?.discount_popup
                                                      ? "green"
                                                      : "gray",
                                                }}
                                              >
                                                İndirim Popup
                                              </Button>
                                            </Badge>
                                          </div>
                                          <div style={{ marginRight: 10 }}>
                                            <Badge
                                              invisible={
                                                !row?.reminder_popup_interected
                                              }
                                              anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "right",
                                              }}
                                              badgeContent={
                                                <CheckCircleOutline
                                                  style={{
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: "green",
                                                    borderRadius: 99,
                                                    color: "#fff",
                                                  }}
                                                />
                                              }
                                            >
                                              <Button
                                                variant="contained"
                                                size="medium"
                                                style={{
                                                  backgroundColor:
                                                    row?.reminder_popup
                                                      ? "green"
                                                      : "gray",
                                                }}
                                              >
                                                Hatırlatma Popup
                                              </Button>
                                            </Badge>
                                          </div>
                                        </div>
                                      );
                                    }
                                  })} */}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                          <div
                            style={{
                              width: "32%",
                              backgroundColor: "#393737",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                height: "8%",
                                backgroundColor: "#448D8C",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: "#fff",
                                letterSpacing: 0.8,
                                fontFamily: "segoeuiB",
                              }}
                            >
                              <div></div>
                              <div> {calcWeeklyOrderStatus()}</div>
                              <div style={{ marginRight: 10, display: "flex" }}>
                                <Settings
                                  onClick={() => {
                                    localStorage.setItem("offers", "reports");
                                    history.push(
                                      `/restaurants/${taskList?.restofficial?.user_id}`
                                    );
                                  }}
                                  onMouseDown={(event) => {
                                    if (event.button === 1) {
                                      localStorage.setItem("offers", "reports");
                                      window.open(
                                        `${window.location.origin}/restaurants/${taskList?.restofficial?.user_id}`,
                                        "_blank"
                                      );
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#393737",
                                height: "90%",
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  height: "auto",
                                  display: "flex",
                                  flexDirection: "row",
                                  height: "70%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: 120,
                                    }}
                                  >
                                    <PieChart
                                      slotProps={{
                                        legend: {
                                          hidden: true,
                                        },
                                      }}
                                      series={[
                                        {
                                          data: taskList?.restofficial?.stats?.product_stats?.map(
                                            (row) => {
                                              // if (row?.total_count === 0) return;
                                              return {
                                                label: `${row?.platform} -  ${row?.total_count} sipariş `,
                                                value: row?.total_amount,
                                                color:
                                                  row?.platform === "Sepettakip"
                                                    ? "yellow"
                                                    : row?.platform ===
                                                      "Sepetapp"
                                                    ? "red"
                                                    : row?.platform ===
                                                      "Masatakip"
                                                    ? "purple"
                                                    : "tomato",
                                              };
                                            }
                                          ),

                                          cx: 120,
                                          cy: 60,
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "row",
                                      flexWrap: "wrap",
                                      marginTop: 4,
                                    }}
                                  >
                                    {taskList?.restofficial?.stats?.product_stats?.map(
                                      (row) => {
                                        if (row?.total_count === 0) return;
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: 10,
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: 10,
                                                height: 10,
                                                backgroundColor:
                                                  row?.platform === "Sepettakip"
                                                    ? "yellow"
                                                    : row?.platform ===
                                                      "Sepetapp"
                                                    ? "red"
                                                    : row?.platform ===
                                                      "Masatakip"
                                                    ? "purple"
                                                    : "tomato",
                                                marginRight: 4,
                                              }}
                                            />
                                            <div
                                              style={{
                                                fontSize: 15,
                                                color: "#fff",
                                              }}
                                            >
                                              {row?.platform} -{" "}
                                              {row?.total_count}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div style={{ height: 120 }}>
                                    <PieChart
                                      slotProps={{
                                        legend: {
                                          hidden: true,
                                        },
                                      }}
                                      series={[
                                        {
                                          data: taskList?.restofficial?.stats?.platform_stats?.map(
                                            (row) => {
                                              if (row?.total_count === 0)
                                                return;
                                              return {
                                                label: `${row?.platform} -  ${row?.total_count} sipariş`,
                                                value: row?.total_amount,
                                                color:
                                                  row?.platform === "Gofody"
                                                    ? "yellow"
                                                    : row?.platform ===
                                                      "Yemeksepeti"
                                                    ? "red"
                                                    : row?.platform === "Getir"
                                                    ? "purple"
                                                    : row?.platform ===
                                                      "Sepetapp"
                                                    ? "black"
                                                    : "tomato",
                                              };
                                            }
                                          ),

                                          cx: 120,
                                          cy: 60,
                                        },
                                      ]}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "row",
                                      flexWrap: "wrap",
                                      marginTop: 4,
                                    }}
                                  >
                                    {taskList?.restofficial?.stats?.platform_stats?.map(
                                      (row) => {
                                        if (row?.total_count === 0) return;
                                        return (
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginRight: 10,
                                            }}
                                          >
                                            <div
                                              style={{
                                                width: 10,
                                                height: 10,
                                                backgroundColor:
                                                  row?.platform === "Gofody"
                                                    ? "yellow"
                                                    : row?.platform ===
                                                      "Yemeksepeti"
                                                    ? "red"
                                                    : row?.platform === "Getir"
                                                    ? "purple"
                                                    : row?.platform ===
                                                      "Sepetapp"
                                                    ? "black"
                                                    : "tomato",
                                                marginRight: 4,
                                              }}
                                            />
                                            <div
                                              style={{
                                                fontSize: 15,
                                                color: "#fff",
                                              }}
                                            >
                                              {row?.platform} -{" "}
                                              {row?.total_count}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  height: "30%",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    height: "50%",
                                    alignItems: "center",
                                    borderBottom: "solid",
                                    borderBottomWidth: 1,
                                    borderBottomColor: "#fff",
                                  }}
                                >
                                  <div
                                    style={{
                                      letterSpacing: 0.8,
                                      fontFamily: "segoeuiB",
                                      width: "auto",
                                      color: "#fff",
                                    }}
                                  >
                                    Mevcut
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      width: "100%",
                                    }}
                                  >
                                    {taskList?.restofficial?.product_usage?.map(
                                      (row1) => {
                                        return (
                                          <Badge
                                            anchorOrigin={{
                                              vertical: "top",
                                              horizontal: "right",
                                            }}
                                            badgeContent={
                                              row1?.usage ? (
                                                <CheckCircleOutline
                                                  style={{
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: "green",
                                                    borderRadius: 99,
                                                    color: "#fff",
                                                  }}
                                                />
                                              ) : (
                                                <HighlightOff
                                                  style={{
                                                    width: 20,
                                                    height: 20,
                                                    backgroundColor: "red",
                                                    borderRadius: 99,
                                                    color: "#fff",
                                                  }}
                                                />
                                              )
                                            }
                                          >
                                            <img
                                              src={
                                                row1?.name === "sepettakip"
                                                  ? sepettakipLogo
                                                  : row1?.name === "callerid"
                                                  ? calleridLogo
                                                  : row1?.name === "sepetfast"
                                                  ? sepetfastLogo
                                                  : row1?.name === "stoktakip"
                                                  ? stoktakipLogo
                                                  : row1?.name === "masatakip"
                                                  ? masatakipLogo
                                                  : row1?.name === "kuryetakip"
                                                  ? courierLogo
                                                  : sepetappLogo
                                              }
                                              alt={row1?.name}
                                              style={{
                                                width: 32,
                                                height: 32,
                                                marginLeft: 3,
                                              }}
                                            />
                                          </Badge>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    height: "50%",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      letterSpacing: 0.8,
                                      fontFamily: "segoeuiB",
                                      width: "15%",
                                      color: "#fff",
                                    }}
                                  >
                                    Teklif Et
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-evenly",
                                      width: "85%",
                                    }}
                                  >
                                    {taskList?.restofficial?.potential_products?.map(
                                      (row1) => {
                                        return (
                                          <div
                                            style={{
                                              backgroundColor: "#393737",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "space-evenly",
                                              width: 80,
                                            }}
                                          >
                                            <img
                                              src={
                                                row1?.name === "sepettakip"
                                                  ? sepettakipLogo
                                                  : row1?.name === "callerid"
                                                  ? calleridLogo
                                                  : row1?.name === "sepetfast"
                                                  ? sepetfastLogo
                                                  : row1?.name === "stoktakip"
                                                  ? stoktakipLogo
                                                  : row1?.name === "masatakip"
                                                  ? masatakipLogo
                                                  : row1?.name === "kuryetakip"
                                                  ? courierLogo
                                                  : sepetappLogo
                                              }
                                              alt={row1?.name}
                                              style={{
                                                width: 32,
                                                height: 32,
                                                marginLeft: 3,
                                              }}
                                            />
                                            <Checkbox
                                              onChange={() => {
                                                let potentialProductsObj =
                                                  Object.assign(
                                                    [],
                                                    JSON.parse(
                                                      JSON.stringify({
                                                        ...potentialProducts,
                                                      })
                                                    )
                                                  );

                                                let index =
                                                  potentialProductsObj.findIndex(
                                                    (item) =>
                                                      item === row1?.name
                                                  );

                                                if (index == -1) {
                                                  potentialProductsObj.push(
                                                    row1?.name
                                                  );
                                                } else {
                                                  potentialProductsObj.splice(
                                                    index,
                                                    1
                                                  );
                                                }

                                                let data = {
                                                  id: taskList?.restofficial
                                                    ?.user_id,
                                                  sendObj: {
                                                    potential_products:
                                                      potentialProductsObj,
                                                  },
                                                };
                                                patchRestOfficialFunc(
                                                  data,
                                                  taskList.id
                                                );
                                                setPotentialProducts([]);
                                                potentialProductsObj = [];
                                              }}
                                              checked={row1?.potential}
                                              style={{
                                                width: 25,
                                                height: 25,
                                                color: "#F0F0F0",
                                              }}
                                            />
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      backgroundColor: "#f4f4f4",
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      maxHeight: 450,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-evenly",
                        backgroundColor: "black",
                      }}
                    >
                      <div style={{ width: "35%", backgroundColor: "#393737" }}>
                        <div
                          style={{
                            width: "96%",
                            height: "90%",
                            margin: 10,
                          }}
                        >
                          <div style={{ height: "50%" }}>
                            <div
                              style={{
                                width: "100%",
                                height: "20%",
                                backgroundColor: "#C50C0C",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: "#fff",
                                marginBottom: 10,
                                letterSpacing: 0.8,
                                fontFamily: "segoeuiB",
                              }}
                            >
                              <div></div>
                              <div>TASK'A AİT NOTLAR</div>
                              <div
                                style={{ marginRight: 10, display: "flex" }}
                              ></div>
                            </div>
                            <div
                              style={{
                                backgroundColor: "#F0F0F0",
                                height: "60%",
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "column",
                                overflow: "auto",
                                boxShadow:
                                  taskList?.calendar_note_history?.length !== 0
                                    ? "#208708 0px 0px 20px 9px"
                                    : "none",
                              }}
                            >
                              {taskList?.calendar_note_history?.map(
                                (row, i) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottomStyle: "solid",
                                        borderBottomWidth: 1,
                                        borderBottomColor: "#C50C0C",
                                      }}
                                    >
                                      <div
                                        key={i}
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          flexDirection: "row",
                                          width: "80%",
                                        }}
                                      >
                                        <li
                                          style={{
                                            marginTop: 4,
                                            marginLeft: 4,
                                            fontFamily: "segoeuiRg",
                                            fontSize: 14,
                                            color: "#000",
                                          }}
                                        >
                                          [{row.title}] : {row?.note}
                                        </li>
                                      </div>
                                      <div
                                        style={{
                                          fontFamily: "segoeuiRg",
                                          fontSize: 11,
                                          width: "20%",
                                          justifyContent: "center",
                                          display: "flex",
                                          alignItems: "center",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <div>
                                          {moment(row?.created_at).format(
                                            "DD-MM-YYYY HH:mm"
                                          )}
                                        </div>
                                        <div>
                                          {moment(row?.created_at).fromNow()}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                          <div style={{ height: "50%" }}>
                            <div
                              style={{
                                width: "100%",
                                height: "8%",
                                backgroundColor: "#C50C0C",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                color: "#fff",
                                marginBottom: 10,
                                letterSpacing: 0.8,
                                fontFamily: "segoeuiB",
                              }}
                            >
                              <div></div>
                              <div>NOTLAR</div>
                              <div
                                style={{ marginRight: 10, display: "flex" }}
                              ></div>
                            </div>
                            <div></div>
                            <div
                              style={{
                                backgroundColor: "#F0F0F0",
                                height: "90%",
                                borderRadius: 4,
                                display: "flex",
                                flexDirection: "column",
                                overflow: "auto",
                              }}
                            >
                              {taskList?.application?.notes?.map((row, i) => {
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      borderBottomStyle: "solid",
                                      borderBottomWidth: 1,
                                      borderBottomColor: "#C50C0C",
                                    }}
                                  >
                                    <div
                                      key={i}
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        flexDirection: "row",
                                        width: "80%",
                                      }}
                                    >
                                      <li
                                        style={{
                                          marginTop: 4,
                                          marginLeft: 4,
                                          fontFamily: "segoeuiRg",
                                          fontSize: 14,
                                          color: "#000",
                                        }}
                                      >
                                        [{row.status}] [
                                        {row.created_by === null
                                          ? "-"
                                          : row?.created_by?.role === "setup"
                                          ? "Kurulum"
                                          : row?.created_by?.role ===
                                            "collector"
                                          ? "Tahsilat"
                                          : row?.created_by?.role}
                                        ] [
                                        {row.created_by === null
                                          ? "-"
                                          : row?.created_by?.full_name}
                                        ] : {row?.note}
                                      </li>
                                    </div>
                                    <div
                                      style={{
                                        fontFamily: "segoeuiRg",
                                        fontSize: 11,
                                        width: "20%",
                                        justifyContent: "center",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div>
                                        {moment(row?.created_at).format(
                                          "DD-MM-YYYY HH:mm"
                                        )}
                                      </div>
                                      <div>
                                        {moment(row?.created_at).fromNow()}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "60%",
                          padding: 20,
                          backgroundColor: "#393737",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: matches ? "row" : "column",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <div style={{ ...styles.formControlContent }}>
                              <FormControl
                                variant="outlined"
                                style={styles.formcontrol}
                              >
                                <InputField
                                  style={{
                                    width: "100%",
                                    height: 62,
                                  }}
                                  label="Restoran"
                                  fieldValue={formData?.restaurant_name || ""}
                                  fieldName={"restaurant_name"}
                                  formData={formData}
                                  setFormData={setFormData}
                                />
                              </FormControl>
                            </div>
                            <div style={styles.formControlContent}>
                              <FormControl
                                variant="outlined"
                                style={styles.formcontrol}
                              >
                                <InputField
                                  style={{
                                    width: "100%",
                                    height: 62,
                                  }}
                                  label="Yetkili Kişi"
                                  fieldValue={formData?.full_name || ""}
                                  fieldName={"full_name"}
                                  formData={formData}
                                  setFormData={setFormData}
                                />
                              </FormControl>
                            </div>
                            {formData?.sector != null && (
                              <div style={styles.formControlContent}>
                                <FormControl
                                  variant="outlined"
                                  style={{
                                    ...styles.formcontrol,
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  <InputLabel style={styles.formlbl}>
                                    Sektör Tipi
                                  </InputLabel>
                                  {sectorList !== null && (
                                    <MuiSelect
                                      name="sector"
                                      className={classes.root}
                                      value={formData?.sector || ""}
                                      onChange={handleChange("sector")}
                                    >
                                      {sectorList?.map((item, idx) => {
                                        return (
                                          <MenuItem
                                            key={idx}
                                            value={item?.value}
                                            id={item?.value}
                                          >
                                            {item?.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </MuiSelect>
                                  )}
                                </FormControl>
                              </div>
                            )}
                            <div style={styles.formControlContent}>
                              <FormControl
                                variant="outlined"
                                style={styles.formcontrol}
                              >
                                <InputField
                                  style={{
                                    width: "100%",
                                    height: 62,
                                  }}
                                  label=" E-mail"
                                  fieldValue={formData?.email || ""}
                                  fieldName={"email"}
                                  formData={formData}
                                  setFormData={setFormData}
                                />
                              </FormControl>
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: matches ? "row" : "column",
                              width: "100%",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              style={{
                                ...styles.formControlContent,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "85%",
                                }}
                              >
                                <div style={{ color: "#fff" }}>
                                  Sabit Telefonu
                                </div>
                                <PhoneInput
                                  countryCodeEditable={false}
                                  country="tr"
                                  onlyCountries={["tr"]}
                                  localization={{ tr: "Türkiye" }}
                                  value={formData?.landline_phone || ""}
                                  containerStyle={{ height: 40 }}
                                  inputStyle={{
                                    height: 40,
                                    width: "100%",
                                    fontSize: 15,
                                    fontWeight: "400",
                                  }}
                                  onChange={(phone) => {
                                    setFormData({
                                      ...formData,
                                      landline_phone: phone,
                                    });
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "15%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: 15,
                                }}
                              >
                                <IconButton
                                  style={{
                                    color: "#fff",

                                    backgroundColor: disabledCallIcon
                                      ? "gray"
                                      : "#448d8c",
                                  }}
                                  disabled={disabledCallIcon}
                                  onClick={() => {
                                    setDisabledCallIcon(true);
                                    setTimeout(() => {
                                      setDisabledCallIcon(false);
                                    }, 5000);
                                    closeSnackbar(callkey);
                                    let phoneNumberv2Obj = Object.assign(
                                      "",
                                      JSON.parse(
                                        JSON.stringify({ ...phoneNumberv2 })
                                      )
                                    );
                                    phoneNumberv2Obj =
                                      taskList?.application?.landline_phone?.replace(
                                        /\s+/g,
                                        ""
                                      );
                                    phoneNumberv2Obj =
                                      phoneNumberv2Obj.slice(-10);

                                    phoneNumberv2Obj =
                                      (JSON.parse(
                                        localStorage.getItem(
                                          "st-officeuser-info"
                                        )
                                      )?.iqcell_verimor
                                        ? "0"
                                        : "90") + phoneNumberv2Obj;

                                    let sendCallData = {
                                      callee: phoneNumberv2Obj,
                                      trigger_source: "task",
                                      task: taskList?.id,
                                      application: taskList?.application?.id,
                                    };

                                    makeCall({
                                      enqueueSnackbar,
                                      closeSnackbar,
                                      sendCallData,
                                      cdrOutboundFunc,
                                      cdrAnswerFunc,
                                      cdrHangupFunc,
                                      callkey,
                                      cdrMissedCountFunc,
                                    });
                                  }}
                                >
                                  <Call />
                                </IconButton>
                              </div>
                            </div>
                            <div
                              style={{
                                ...styles.formControlContent,
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "85%",
                                }}
                              >
                                <div style={{ color: "#fff" }}>
                                  Cep Telefonu
                                </div>
                                <PhoneInput
                                  countryCodeEditable={false}
                                  country="tr"
                                  onlyCountries={["tr"]}
                                  localization={{ tr: "Türkiye" }}
                                  value={formData?.phone || ""}
                                  containerStyle={{ height: 40 }}
                                  inputStyle={{
                                    height: 40,
                                    width: "100%",
                                    fontSize: 15,
                                    fontWeight: "400",
                                  }}
                                  onChange={(phone) => {
                                    setFormData({ ...formData, phone: phone });
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  width: "15%",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginTop: 15,
                                }}
                              >
                                <IconButton
                                  style={{
                                    color: "#fff",
                                    backgroundColor: disabledCallIcon
                                      ? "gray"
                                      : "#448d8c",
                                  }}
                                  disabled={disabledCallIcon}
                                  onClick={() => {
                                    setDisabledCallIcon(true);
                                    setTimeout(() => {
                                      setDisabledCallIcon(false);
                                    }, 5000);
                                    closeSnackbar(callkey);
                                    let phoneNumberv2Obj = Object.assign(
                                      "",
                                      JSON.parse(
                                        JSON.stringify({ ...phoneNumberv2 })
                                      )
                                    );

                                    phoneNumberv2Obj =
                                      taskList?.application?.phone?.replace(
                                        /\s+/g,
                                        ""
                                      );
                                    phoneNumberv2Obj =
                                      phoneNumberv2Obj.slice(-10);

                                    phoneNumberv2Obj =
                                      (JSON.parse(
                                        localStorage.getItem(
                                          "st-officeuser-info"
                                        )
                                      )?.iqcell_verimor
                                        ? "0"
                                        : "90") + phoneNumberv2Obj;

                                    let sendCallData = {
                                      callee: phoneNumberv2Obj,
                                      trigger_source: "task",
                                      task: taskList?.id,
                                      application: taskList?.application?.id,
                                    };

                                    makeCall({
                                      enqueueSnackbar,
                                      closeSnackbar,
                                      sendCallData,
                                      cdrOutboundFunc,
                                      cdrAnswerFunc,
                                      cdrHangupFunc,
                                      callkey,
                                      cdrMissedCountFunc,
                                    });
                                  }}
                                >
                                  <Call />
                                </IconButton>
                              </div>
                            </div>

                            <div
                              style={{
                                ...styles.formControlContent,
                                alignSelf: "center",
                              }}
                            >
                              <FormControl
                                variant="outlined"
                                style={{
                                  ...styles.formcontrol,
                                  backgroundColor: "#fff",
                                }}
                              >
                                <InputLabel style={styles.formlbl}>
                                  Mutfak Tipi
                                </InputLabel>
                                <MuiSelect
                                  name="foodcategory"
                                  className={classes.root}
                                  value={parseInt(formData?.foodcategory) || ""}
                                  onChange={handleChange("foodcategory")}
                                >
                                  {foodCategory?.map((item, idx) => {
                                    return (
                                      <MenuItem
                                        key={idx}
                                        value={parseInt(item?.id)}
                                        id={item?.id}
                                      >
                                        {item?.category}
                                      </MenuItem>
                                    );
                                  })}
                                </MuiSelect>
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        <div
                          style={{
                            flexDirection: matches ? "row" : "column",
                            display: "flex",
                            marginTop: 20,
                          }}
                        >
                          <div
                            style={{
                              ...styles.formControlContent,
                              alignSelf: "center",
                            }}
                          >
                            <FormControl
                              variant="outlined"
                              style={{
                                ...styles.formcontrol,
                                backgroundColor: "#fff",
                              }}
                            >
                              <InputLabel style={styles.formlbl}>
                                İl Seçimi
                              </InputLabel>
                              <MuiSelect
                                name="city"
                                className={classes.root}
                                value={parseInt(formData?.city) || ""}
                                onChange={handleChange("city")}
                              >
                                {cities?.map((city, idx) => {
                                  return (
                                    <MenuItem
                                      key={idx}
                                      value={parseInt(city?.id)}
                                      id={city?.id}
                                    >
                                      {city?.name}
                                    </MenuItem>
                                  );
                                })}
                              </MuiSelect>
                            </FormControl>
                          </div>
                          <div
                            style={{
                              ...styles.formControlContent,
                              alignSelf: "center",
                            }}
                          >
                            <FormControl
                              variant="outlined"
                              style={{
                                ...styles.formcontrol,
                                backgroundColor: "#fff",
                              }}
                            >
                              {" "}
                              <InputLabel style={styles.formlbl}>
                                İlçe Seçimi
                              </InputLabel>
                              <MuiSelect
                                name="town"
                                className={classes.root}
                                value={parseInt(formData?.town) || ""}
                                onChange={handleChange("town")}
                              >
                                {town?.map((townn, idx) => {
                                  return (
                                    <MenuItem
                                      key={idx}
                                      value={parseInt(townn?.id)}
                                      id={townn?.id}
                                    >
                                      {townn?.name}
                                    </MenuItem>
                                  );
                                })}
                              </MuiSelect>
                            </FormControl>
                          </div>
                          <div
                            style={{
                              ...styles.formControlContent,
                              alignSelf: "center",
                            }}
                          >
                            <FormControl
                              variant="outlined"
                              style={{
                                ...styles.formcontrol,
                                backgroundColor: "#fff",
                              }}
                            >
                              <InputLabel style={styles.formlbl}>
                                Mahalle Seçimi
                              </InputLabel>
                              <MuiSelect
                                name="neighborhood"
                                className={classes.root}
                                value={parseInt(formData?.neighborhood) || ""}
                                onChange={handleChange("neighborhood")}
                              >
                                {neigh?.length > 0 &&
                                  neigh?.map((item, idx) => {
                                    return (
                                      <MenuItem
                                        key={idx}
                                        value={item?.id}
                                        id={item?.id}
                                      >
                                        {item?.name}
                                      </MenuItem>
                                    );
                                  })}
                              </MuiSelect>
                            </FormControl>
                          </div>
                          <div style={styles.formControlContent}>
                            <FormControl
                              variant="outlined"
                              style={styles.formcontrol}
                            >
                              <InputField
                                style={{
                                  width: "20%",
                                  height: 62,
                                }}
                                label="Cadde / Sokak"
                                fieldValue={formData?.street}
                                fieldName={"street"}
                                formData={formData}
                                setFormData={setFormData}
                              />
                            </FormControl>
                          </div>
                          <div style={styles.formControlContent}>
                            <FormControl
                              variant="outlined"
                              style={styles.formcontrol}
                            >
                              <InputField
                                style={{
                                  width: "20%",
                                  height: 62,
                                }}
                                label="Kapı No"
                                fieldValue={formData?.door_number}
                                fieldName={"door_number"}
                                formData={formData}
                                setFormData={setFormData}
                              />
                            </FormControl>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: 20,
                            flexDirection: matches ? "row" : "column",
                            width: "100%",
                            justifyContent: "space-around",
                          }}
                        >
                          <div style={styles.formControlContent}>
                            <FormControl
                              variant="outlined"
                              style={styles.formcontrol}
                            >
                              <InputField
                                style={{
                                  width: "100%",
                                  height: 62,
                                }}
                                type="number"
                                label="Latitude"
                                fieldValue={formData?.latitude}
                                fieldName={"latitude"}
                                formData={formData}
                                setFormData={setFormData}
                              />
                            </FormControl>
                          </div>
                          <div style={styles.formControlContent}>
                            <FormControl
                              variant="outlined"
                              style={styles.formcontrol}
                            >
                              <InputField
                                style={{
                                  width: "100%",
                                  height: 62,
                                }}
                                type="number"
                                label="Longitude"
                                fieldValue={formData?.longitude}
                                fieldName={"longitude"}
                                formData={formData}
                                setFormData={setFormData}
                              />
                            </FormControl>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              width: "10%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                fontFamily: "segoeuiB",
                                letterSpacing: 0.8,
                                color: "#fff",
                              }}
                            >
                              Şube
                            </div>
                            <div style={{ marginLeft: 4 }}>
                              <Select
                                styles={selectDefaultStyles}
                                options={offerCount}
                                value={{
                                  value: taskList?.application?.branch_count,
                                  label: taskList?.application?.branch_count,
                                }}
                                isSearchable={false}
                                hoverColor={"#AB1818"}
                                selectWidth={80}
                                menuColor={"#AB1818"}
                                color={"#AB1818"}
                                placeholder={""}
                                onChange={(e) => {
                                  let data = {
                                    task_id: taskList?.id,
                                    app_id: taskList?.application?.id,
                                    sendObj: { branch_count: e.value },
                                  };
                                  taskApplicationUpdateFunc(data);
                                }}
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "60%",
                              justifyContent: "space-between",
                            }}
                          >
                            {taskList?.application?.potential_products?.map(
                              (row1) => {
                                return (
                                  <div
                                    style={{
                                      backgroundColor: "#393737",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-evenly",
                                      width: 80,
                                    }}
                                  >
                                    <img
                                      src={
                                        row1?.name === "sepettakip"
                                          ? sepettakipLogo
                                          : row1?.name === "callerid"
                                          ? calleridLogo
                                          : row1?.name === "sepetfast"
                                          ? sepetfastLogo
                                          : row1?.name === "stoktakip"
                                          ? stoktakipLogo
                                          : row1?.name === "masatakip"
                                          ? masatakipLogo
                                          : row1?.name === "kuryetakip"
                                          ? courierLogo
                                          : sepetappLogo
                                      }
                                      alt={row1?.name}
                                      style={{
                                        width: 32,
                                        height: 32,
                                        marginLeft: 3,
                                      }}
                                    />
                                    <Checkbox
                                      onChange={() => {
                                        const potentialProductsObj =
                                          Object.assign(
                                            [],
                                            JSON.parse(
                                              JSON.stringify({
                                                ...potentialProductsApp,
                                              })
                                            )
                                          );

                                        let index =
                                          potentialProductsObj.findIndex(
                                            (item) => item === row1?.name
                                          );

                                        if (index == -1) {
                                          potentialProductsObj.push(row1?.name);
                                        } else {
                                          potentialProductsObj.splice(index, 1);
                                        }

                                        let data = {
                                          task_id: taskList?.id,
                                          app_id: taskList?.application?.id,
                                          sendObj: {
                                            potential_products:
                                              potentialProductsObj,
                                          },
                                        };
                                        taskApplicationUpdateFunc(data);
                                        setPotentialProductsApp([]);
                                      }}
                                      checked={row1?.potential}
                                      style={{
                                        width: 25,
                                        height: 25,
                                        color: "#F0F0F0",
                                      }}
                                    />
                                  </div>
                                );
                              }
                            )}
                          </div>
                          <Button
                            variant="contained"
                            size="large"
                            style={{
                              fontFamily: "segoeuiB",
                              width: 160,
                              fontWeight: 700,
                              backgroundColor: "#AB1818",
                            }}
                            onClick={() => sendRegistration(formData.id)}
                          >
                            Güncelle
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    width: "100%",
                    height: "30%",
                    backgroundColor: "black",
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop: 10,
                  }}
                >
                  <div
                    style={{
                      width: taskList?.application !== null ? "35%" : "32%",
                      backgroundColor: "#393737",
                      marginLeft: taskList?.application !== null ? 8 : 0,
                    }}
                  >
                    {userDetail?.role !== "tech" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              margin: 10,
                              letterSpacing: 0.8,
                              fontFamily: "segoeuiB",
                              color: "#fff",
                            }}
                          >
                            Bugünkü Randevularım
                          </div>
                          <div style={{ padding: 5 }}>
                            {taskList?.show_summary && (
                              <Assignment
                                onClick={() => {
                                  setNotPayedModal(true);
                                }}
                                style={{
                                  color: "#fff",
                                  width: 32,
                                  height: 32,
                                  cursor: "pointer",
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "80%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              maxHeight: "35%",
                              overflow: "auto",
                            }}
                          >
                            {calendarList !== null &&
                              countAndShowOccurrences(calendarList).map(
                                (row) => {
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        backgroundColor: "darkgray",
                                        marginRight: 10,
                                        padding: 3,
                                        borderRadius: 4,
                                        fontFamily: "segoeuiB",
                                        letterSpacing: 0.8,
                                        fontSize: 14,
                                      }}
                                    >
                                      {row.title} : {row.count}
                                    </div>
                                  );
                                }
                              )}
                          </div>
                          <div style={{ overflow: "auto", maxHeight: "60%" }}>
                            {calendarList?.map((row) => {
                              return (
                                <div style={{ marginLeft: 4 }}>
                                  <li
                                    style={{
                                      fontFamily: "segoeuiRg",
                                      fontSize: 14,
                                      color: "#fff",
                                      marginTop: 10,
                                    }}
                                  >
                                    [{row?.title}] [
                                    {moment(row.start_at).format("HH:mm")}] :{" "}
                                    {row?.restofficial !== null
                                      ? row?.restofficial?.name
                                      : row?.application?.name + " (Başvuru) "}
                                  </li>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <SearchWithIcon
                            setSearchTerm={setSearchTermCalendar}
                            placeTitle={"Randevu Ara"}
                            customWidth={"50%"}
                            searchTerm={searchTermCalendar}
                          />
                        </div>
                        <div style={{ overflow: "auto", maxHeight: "190px" }}>
                          {calendarList
                            ?.filter((list) =>
                              list.restofficial?.name
                                .toLocaleLowerCase("tr")
                                .includes(
                                  searchTermCalendar.toLocaleLowerCase("tr")
                                )
                            )
                            .map((row) => {
                              return (
                                <div
                                  style={{
                                    marginLeft: 4,
                                    display: "flex",
                                    marginTop: 10,
                                    alignItems: "center",
                                  }}
                                >
                                  <ArrowCircleRight
                                    onClick={() => {
                                      let data = {
                                        calendar_id: row?.id,
                                      };
                                      taskFromCalendarFunc(data);
                                      setSearchTermCalendar("");
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      color: "#448d8c",
                                    }}
                                  />
                                  <li
                                    style={{
                                      fontFamily: "segoeuiRg",
                                      fontSize: 14,
                                      color: "#fff",
                                      marginLeft: 10,
                                    }}
                                  >
                                    [{row?.title}] [
                                    {moment(row.start_at).format(
                                      "DD-MM-YYYY HH:mm"
                                    )}
                                    ] :{" "}
                                    {row?.restofficial !== null
                                      ? row?.restofficial?.name
                                      : row?.application?.name + " (Başvuru) "}
                                  </li>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </div>
                  {taskList?.completed ? (
                    <div
                      style={{
                        width:
                          taskList?.application !== null ? "62.5%" : "65.5%",
                        backgroundColor: "#393737",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: 34,
                        fontFamily: "segoeuiB",
                        color: "#fff",
                        marginRight: taskList?.application !== null ? 5 : 0,
                      }}
                    >
                      <div>Tamamlanmış Görev</div>
                      {taskList?.revertable && (
                        <div style={{ marginTop: 30 }}>
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              height: 40,
                            }}
                            size="small"
                            onClick={() => {
                              taskRevertFunc({
                                task_id: taskList?.id,
                              });
                              setSelectedHeader(null);
                            }}
                          >
                            Görevi Geri Al
                          </Button>
                        </div>
                      )}
                      {taskList?.resetable && (
                        <div style={{ marginTop: 30 }}>
                          <Button
                            style={{
                              backgroundColor: "#f53b62",
                              color: "#fff",
                              fontFamily: "segoeuiB",
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              height: 40,
                            }}
                            size="small"
                            onClick={() => {
                              taskResetFunc({
                                task_id: taskList?.id,
                              });
                              setSelectedHeader(null);
                            }}
                          >
                            Randevuyu Öne Al
                          </Button>
                        </div>
                      )}
                      {!taskList?.revertable && !taskList?.resetable && (
                        <div style={{ fontSize: 20, color: "gray" }}>
                          {" "}
                          Görev Geri Alınamaz
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      style={{
                        width:
                          taskList?.application !== null ? "62.5%" : "65.5%",
                        backgroundColor: "#393737",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "80%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            {taskList?.task_actions?.map((row) => {
                              return (
                                <FormControlLabel
                                  labelPlacement="top"
                                  control={
                                    <Checkbox
                                      checked={selectedHeader?.id === row?.id}
                                      onChange={(e) => {
                                        if (selectedHeader?.id === row?.id) {
                                          setSelectedHeader(null);
                                        } else {
                                          setSelectedHeader(row);
                                        }
                                      }}
                                      sx={{
                                        color: "#fff",
                                        backgroundColor: "#393737",
                                        "&.Mui-checked": {
                                          color: "#fff",
                                        },
                                      }}
                                    />
                                  }
                                  style={{ textAlign: "center" }}
                                  label={
                                    <span
                                      style={{
                                        // fontFamily: "Sego",
                                        fontSize: 12,
                                        color: "#fff",
                                      }}
                                    >
                                      {row?.name}
                                    </span>
                                  }
                                />
                              );
                            })}
                          </div>
                          {selectedHeader?.tag === "delete_application" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                width:
                                  selectedDeleteReason?.id === "other"
                                    ? "96%"
                                    : "50%",
                                alignItems: "center",
                              }}
                            >
                              <Select
                                responsiveHeight={30}
                                selectFontSize={16}
                                options={blockedReasons}
                                styles={selectDefaultStyles}
                                isSearchable={false}
                                zIndex={90}
                                hoverColor={"#f53b62"}
                                menuColor={"#f53b62"}
                                selectWidth={250}
                                placeholder={"Tümü"}
                                menuPlacement="top"
                                value={selectedDeleteReason}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => {
                                  setSelectedDeleteReason(e);
                                }}
                                getOptionLabel={(e) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {e.label}
                                  </div>
                                )}
                              />
                            </div>
                          )}
                          {selectedHeader?.tag === "tech" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                width:
                                  selectedDeleteReason?.id === "other"
                                    ? "96%"
                                    : "50%",
                                alignItems: "center",
                              }}
                            >
                              <Select
                                responsiveHeight={30}
                                selectFontSize={16}
                                options={ticketTypes}
                                styles={selectDefaultStyles}
                                isSearchable={false}
                                zIndex={90}
                                hoverColor={"#f53b62"}
                                menuColor={"#f53b62"}
                                selectWidth={250}
                                menuPlacement="top"
                                placeholder={"Seçiniz"}
                                value={selectedTicketType}
                                getOptionValue={(option) => option.id}
                                onChange={(e) => {
                                  setSelectedTicketType(e);
                                }}
                                getOptionLabel={(e) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {e.ticket_type}
                                  </div>
                                )}
                              />
                            </div>
                          )}

                          {selectedHeader?.tag === "create_ticket" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "row",
                                width:
                                  selectedDeleteReason?.id === "other"
                                    ? "96%"
                                    : "50%",
                                alignItems: "center",
                              }}
                            >
                              <Select
                                responsiveHeight={30}
                                selectFontSize={16}
                                options={backofficeList}
                                styles={selectDefaultStyles}
                                isSearchable={false}
                                zIndex={90}
                                hoverColor={"#f53b62"}
                                menuColor={"#f53b62"}
                                selectWidth={250}
                                menuPlacement="top"
                                placeholder={"Seçiniz"}
                                value={selectedPerson}
                                getOptionValue={(option) => option.user_id}
                                onChange={(e) => {
                                  setSelectedPerson(e);
                                }}
                                getOptionLabel={(e) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {e.full_name}
                                  </div>
                                )}
                              />
                              <div style={{ marginLeft: 20 }}>
                                <Select
                                  responsiveHeight={30}
                                  selectFontSize={16}
                                  options={ticketTypes}
                                  styles={selectDefaultStyles}
                                  isSearchable={false}
                                  zIndex={90}
                                  hoverColor={"#f53b62"}
                                  menuColor={"#f53b62"}
                                  selectWidth={250}
                                  menuPlacement="top"
                                  placeholder={"Seçiniz"}
                                  value={selectedTicketType}
                                  getOptionValue={(option) => option.id}
                                  onChange={(e) => {
                                    setSelectedTicketType(e);
                                  }}
                                  getOptionLabel={(e) => (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {e.ticket_type}
                                    </div>
                                  )}
                                />
                              </div>
                            </div>
                          )}
                          {selectedHeader?.tag === "lower_payment_request" && (
                            <div style={{ margin: "10px 0px" }}>
                              {lowerPaymentPdf && (
                                <PictureAsPdfIcon style={{ color: "red" }} />
                              )}
                              <input
                                name="file"
                                type="file"
                                accept="application/pdf"
                                onChange={(e) => onChangePdf(e)}
                                style={{ color: "#fff" }}
                              />
                            </div>
                          )}

                          {selectedHeader?.tag === "completed" &&
                            userDetail?.department === "Operasyon Yonetimi" && (
                              <InputBase
                                style={{
                                  fontFamily: "segoeuiRg",
                                  paddingLeft: 8,
                                  background: "#fff",
                                  margin: "10px 0px",
                                }}
                                placeholder={"Gün"}
                                value={selectedDay}
                                onChange={(e) => {
                                  setSelectedDay(e.target.value);
                                }}
                              />
                            )}

                          {selectedHeader?.tag === "delete_application" ? (
                            <div style={{ minHeight: 42 }} />
                          ) : (
                            <Paper
                              style={{
                                width: "90%",
                                display: "flex",
                                justifyContent: "space-between",
                                minHeight:
                                  selectedHeader?.tag === "tech" ||
                                  selectedHeader?.tag === "create_ticket"
                                    ? 30
                                    : 42,
                                backgroundColor: "#F0F0F0",
                                alignSelf: "center",
                                marginTop:
                                  selectedHeader?.tag === "tech" ||
                                  selectedHeader?.tag === "create_ticket"
                                    ? 10
                                    : 0,
                              }}
                            >
                              <InputBase
                                disabled={selectedHeader?.note ? false : true}
                                multiline
                                spellCheck={false}
                                style={{
                                  width: "100%",
                                  fontFamily: "segoeuiRg",
                                  paddingLeft: 8,
                                }}
                                rows={
                                  selectedHeader?.tag === "tech" ||
                                  selectedHeader?.tag === "create_ticket" ||
                                  selectedHeader?.tag ===
                                    "lower_payment_request" ||
                                  (selectedHeader?.tag === "completed" &&
                                    userDetail?.department ===
                                      "Operasyon Yonetimi")
                                    ? 3
                                    : 5
                                }
                                placeholder={"Not ekle"}
                                className={classes.input}
                                value={note}
                                onChange={(e) => {
                                  setNote(e.target.value);
                                }}
                              />
                            </Paper>
                          )}

                          <div
                            style={{
                              width: "90%",
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: 10,
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "auto",
                              }}
                            >
                              <DatePicker
                                selected={startDate}
                                timeIntervals={5}
                                disabled={
                                  selectedHeader?.calendar ? false : true
                                }
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                }}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect
                                maxDate={addDays(
                                  new Date(),
                                  taskList?.max_allowed_days
                                )}
                                excludeTimes={calendarAllList?.map((row) => {
                                  if (
                                    startDate.getDate() ==
                                    parseInt(moment(row?.start_at).format("DD"))
                                  ) {
                                    return setHours(
                                      setMinutes(
                                        new Date(),
                                        moment(row?.start_at).format("mm")
                                      ),
                                      moment(row?.start_at).format("HH")
                                    );
                                  }
                                })}
                                minDate={moment(new Date()).toDate()}
                                dateFormat="d MMMM, HH:mm"
                                locale="tr"
                                customInput={
                                  <CustomDatePickerInput
                                    selectWidth={350}
                                    color={
                                      selectedHeader?.calendar
                                        ? "#f53b62"
                                        : "gray"
                                    }
                                  />
                                }
                              />
                            </div>
                            <Button
                              style={{
                                backgroundColor:
                                  selectedHeader !== null &&
                                  selectedHeader?.calendar
                                    ? "purple"
                                    : "#AB1818",
                                color: "#fff",
                                fontFamily: "segoeuiB",
                                width: "20%",
                                display: "flex",
                                justifyContent: "center",
                                height: 40,
                              }}
                              size="small"
                              onClick={() => {
                                let data;
                                if (selectedHeader !== null) {
                                  if (
                                    selectedHeader?.tag === "delete_application"
                                  ) {
                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: {
                                        delete_reason: selectedDeleteReason?.id,
                                        action: selectedHeader?.id,
                                      },
                                    };
                                  } else if (selectedHeader?.tag === "tech") {
                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: {
                                        ticket_type: selectedTicketType?.id,
                                        action: selectedHeader?.id,
                                        note,
                                      },
                                    };
                                  } else if (
                                    selectedHeader?.tag ===
                                    "create_collector_ticket"
                                  ) {
                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: {
                                        action: selectedHeader?.id,
                                        ticket_type: 11,
                                        note,
                                      },
                                    };
                                  } else if (
                                    selectedHeader?.tag === "create_ticket"
                                  ) {
                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: {
                                        action: selectedHeader?.id,
                                        ticket_type: selectedTicketType?.id,
                                        note,
                                        ticket_officeuser_id:
                                          selectedPerson?.user_id,
                                      },
                                    };
                                  } else if (
                                    selectedHeader?.tag ===
                                    "lower_payment_request"
                                  ) {
                                    const formData = new FormData();
                                    formData.append(
                                      "lower_payment_request_file",
                                      lowerPaymentPdf
                                    );
                                    formData.append(
                                      "action",
                                      selectedHeader?.id
                                    );
                                    formData.append("note", note);

                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: formData,
                                    };
                                  } else if (
                                    selectedHeader?.tag === "completed" &&
                                    userDetail?.department ===
                                      "Operasyon Yonetimi"
                                  ) {
                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: {
                                        discount_active_days:
                                          Number(selectedDay),
                                        action: selectedHeader?.id,
                                        note,
                                      },
                                    };
                                  } else if (
                                    selectedHeader?.note &&
                                    selectedHeader?.calendar
                                  ) {
                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: {
                                        note,
                                        start_at:
                                          moment(startDate).toISOString(true),
                                        action: selectedHeader?.id,
                                      },
                                    };
                                  } else if (
                                    !selectedHeader?.note &&
                                    selectedHeader?.calendar
                                  ) {
                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: {
                                        start_at:
                                          moment(startDate).toISOString(true),
                                        action: selectedHeader?.id,
                                      },
                                    };
                                  } else if (
                                    selectedHeader?.note &&
                                    !selectedHeader?.calendar
                                  ) {
                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: {
                                        note,
                                        action: selectedHeader?.id,
                                      },
                                    };
                                  } else {
                                    data = {
                                      task_id: taskList?.id,
                                      tag: selectedHeader?.tag,
                                      sendObj: {
                                        action: selectedHeader?.id,
                                      },
                                    };
                                  }
                                } else {
                                  alert("Aksiyon seçilmedi.");
                                }
                                if (
                                  taskList?.task_type?.name === "Bloke" &&
                                  selectedHeader?.tag === "completed"
                                ) {
                                  setBlockPotentialModal(true);
                                } else {
                                  if (selectedHeader?.note) {
                                    if (note.length > 3) {
                                      if (taskList?.reminders?.length !== 0) {
                                        taskList?.reminders?.map((row) => {
                                          if (
                                            row?.type !== "officeusertasktype"
                                          ) {
                                            if (
                                              row?.history?.some((row1) => {
                                                return (
                                                  row1?.from !== "officeuser"
                                                );
                                              })
                                            ) {
                                              data.sendObj.reminder = row?.id;
                                            }
                                            setReminderModal(true);
                                          }
                                        });
                                      }
                                      taskActionFunc(data);
                                      setNote("");
                                      setSelectedHeader(null);
                                      setStartDate(
                                        setHours(
                                          setMinutes(
                                            new Date(),
                                            moment().format("mm")
                                          ),
                                          moment().format("HH")
                                        )
                                      );
                                      setSelectedTicketType(null);
                                    } else {
                                      alert(
                                        "Not alanı en az 3 karakter olmalıdır."
                                      );
                                    }
                                  } else {
                                    if (taskList?.reminders?.length !== 0) {
                                      taskList?.reminders?.map((row) => {
                                        if (
                                          row?.type !== "officeusertasktype"
                                        ) {
                                          if (
                                            row?.history?.some((row1) => {
                                              return (
                                                row1?.from !== "officeuser"
                                              );
                                            })
                                          ) {
                                            data.sendObj.reminder = row?.id;
                                          }
                                          setReminderModal(true);
                                        }
                                      });
                                    }
                                    taskActionFunc(data);
                                    setNote("");
                                    setSelectedHeader(null);
                                    setStartDate(
                                      setHours(
                                        setMinutes(
                                          new Date(),
                                          moment().format("mm")
                                        ),
                                        moment().format("HH")
                                      )
                                    );
                                  }
                                }
                              }}
                            >
                              {selectedHeader !== null &&
                              selectedHeader?.calendar
                                ? "Takvime Gönder"
                                : "Tamamla"}
                            </Button>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "20%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <Paper
                            style={{
                              width: "90%",
                              display: "flex",
                              backgroundColor: "#393737",
                              alignSelf: "center",
                              height: "80%",
                              flexDirection: "column",
                            }}
                          >
                            {personalTemplateNoteList?.map((row, ind) => {
                              if (ind > 5) return;
                              return (
                                <div
                                  style={{
                                    padding: 2,
                                    backgroundColor: "#F0F0F0",
                                    margin: 3,
                                    borderRadius: 4,
                                    cursor: "pointer",
                                    fontSize: 14,
                                  }}
                                  onClick={() => {
                                    if (selectedHeader?.note) {
                                      setNote(row?.text);
                                    }
                                  }}
                                >
                                  {row?.text?.length > 15 ? (
                                    row?.text?.slice(0, 15) + "..."
                                  ) : (
                                    <span>{row?.text}</span>
                                  )}
                                </div>
                              );
                            })}
                          </Paper>
                          <div>
                            <IconButton
                              onClick={() => {
                                setUserNotesModal(true);
                              }}
                            >
                              <AddBox style={{ color: "#fff" }} />
                            </IconButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>

      <ApplicationActions
        applicationActionsModal={applicationActionsModal}
        setApplicationActionsModal={setApplicationActionsModal}
        applicationId={applicationId}
        pageStatus={pageStatus}
        setApplicationId={setApplicationId}
        getNotes={
          applicationTypes === "application"
            ? applicationNotesFunc
            : getTrackerNotes
        }
        notes={
          applicationTypes === "application" ? applicationNotes : trackerNotes
        }
        data_type={applicationTypes}
        createNewNote={
          applicationTypes === "application"
            ? applicationNotesCreateFunc
            : postTrackerNotes
        }
      />
      <UserNotesModal
        userNotesModal={userNotesModal}
        setUserNotesModal={setUserNotesModal}
        personalTemplateNoteList={personalTemplateNoteList}
      />

      <RestPrevOfferHistoryModal
        restPrevOfferHistoryModal={restPrevOfferHistoryModal}
        setRestPrevOfferHistoryModal={setRestPrevOfferHistoryModal}
        currentRow={currentRow}
      />

      <ActionModal
        actionModal={actionModal}
        setActionModal={setActionModal}
        actionModalResId={actionModalResId}
        setActionModalResId={setActionModalResId}
        actionVisiblity={false}
      />

      <LockedModal
        lockedModal={lockedModal}
        setLockedModal={setLockedModal}
        currentRowForUnlock={currentRowForUnlock}
      />

      <PayedModal
        taskPage={true}
        showPayment={true}
        payedModal={payedModal}
        setPayedModal={setPayedModal}
        r_id={restId}
      />

      <BlockPotetialModal
        blockPotentialModal={blockPotentialModal}
        setBlockPotentialModal={setBlockPotentialModal}
        selectedPotentialType={selectedPotentialType}
        setSelectedPotentialType={setSelectedPotentialType}
      />

      <OfferPriceChangeModal
        offerPriceChangeModal={offerPriceChangeModal}
        setOfferPriceChangeModal={setOfferPriceChangeModal}
        financeOfferRow={financeOfferRow}
        setFinanceOfferRow={setFinanceOfferRow}
      />

      <NotPayedModal
        notPayedModal={notPayedModal}
        setNotPayedModal={setNotPayedModal}
      />

      <OfferModalV2
        offerModal={offerModal}
        setOfferModal={setOfferModal}
        offerCode={
          taskList?.restofficial?.offers?.find((obj) => !obj.payed)?.code
        }
      />

      <ReminderModal
        reminderModal={reminderModal}
        setReminderModal={setReminderModal}
        reminderType="show"
        reminderRow={reminderRow}
        setReminderRow={setReminderRow}
      />

      <LimitedUseModal
        limitedUseModal={limitedUseModal}
        setLimitedUseModal={setLimitedUseModal}
        restaurantLimitedUse={restaurantLimitedUse}
        restOfficialData={taskList?.restofficial}
      />

      <ProductPhotoModal
        productPhotoModal={productPhotoModal}
        setProductPhotoModal={setProductPhotoModal}
        setOpenProductPoolModal={setOpenProductPoolModal}
        currentProductType={"task"}
        taskId={taskList?.id}
      />

      <ProductPoolModal
        openProductPoolModal={openProductPoolModal}
        setOpenProductPoolModal={setOpenProductPoolModal}
        setProductPhotoModal={setProductPhotoModal}
      />

      <ProductProfitModal
        productProfitModal={productProfitModal}
        setProductProfitModal={setProductProfitModal}
        currentProfitRestId={currentProfitRestId}
        setCurrentProfitRestId={setCurrentProfitRestId}
      />

      <ReferencePhoneModal
        referencePhoneModal={referencePhoneModal}
        setReferencePhoneModal={setReferencePhoneModal}
        currentTask={currentTask}
      />
    </>
  );
};

const styles = {
  container: { marginTop: 32, marginLeft: 24 },
  content: {
    height: "98%",
    width: "98%",
    borderRadius: 4,
    display: "flex",
    contain: "content",
    justifyContent: "space-between",
  },
  iconContent: {
    display: "inline-block",
    borderRadius: 4,
    width: 56,
    height: 52,
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    backgroundColor: "#319a77",
    marginLeft: 3,
    marginBottom: 5,
    marginTop: 5,
  },
  icon: {
    width: 40,
    height: 40,
    color: "#fff",
    marginTop: 5,
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    cursor: "pointer",
  },
  disabledIcon: {
    color: "#666",
    cursor: "not-allowed",
  },
  formControlContent: {
    flex: 1,
    borderRadius: 4,
    marginRight: 14,
    marginBottom: 16,
  },
  formcontrol: {
    width: "100%",
    borderRadius: "6px",
  },
  formlbl: {
    marginTop: 10,
    fontSize: 16,
    color: "#585858",
    fontFamily: "segoeuiRg",
    fontWeight: 700,
    marginLeft: 8,
  },

  ratioText: {
    margin: 0,
    padding: 0,
    fontSize: 10,
    width: "fit-content",
  },
};

export default Tasks;
